import React, { useEffect } from 'react'
import './contentFooter.css'

import { PropTypes } from 'prop-types'
import { Grid } from "uikit-react"
import resizeHandler from '../../../utils/sizeScreen'

import { Logo } from '../../atoms/logo/Logo'
import { ListContent } from '../../molecules/lists/listContent/ListContent'
import { SocialIcons } from '../../molecules/socialIcons/SocialIcons'
import { NewsletterSub } from '../../molecules/newslaterSub/NewsletterSub'
import separator from "../../../assets/iconsSVG/original/footerline.svg"




 
export const ContentFooter = ({ListContent1,ListContent2,newsletter,arrayIcons}) => {
    const [size, setSize] = React.useState("");
    const sizeScreen =() => { setSize(resizeHandler())}
  
    useEffect(() => {
      sizeScreen();
    }, []);
  
    window.addEventListener('resize', sizeScreen);
  
  
  return (
    <div className='contentFooter'>
        <div className='alignContentFooter'>
            <img className='separatorFooter' src={separator} alt="-----"/>
            <div className="logosContFooter">
                <div className='logoCentred'>
                    <Logo horizontal={size == "small"? false: true} color="white" /> 
                </div>
                <SocialIcons arrayIcons={arrayIcons} gap={ "5vh" } />
            </div>
            <div className="uk-column-1-2@s textContFooter">
                <div className='uk-column-1-2@s'>
                    <ListContent title={ListContent1.title}  arrayLinks={ListContent1.content} />
                    <ListContent title={ListContent2.title} arrayLinks={ListContent2.content} />
                </div>
                <NewsletterSub 
                    title={newsletter.title} 
                    content={newsletter.content} 
                    placeholder={newsletter.placeholder}
                    textButton={newsletter.textButton}
                />
            </div>
        </div>
    </div>
  )
}

const arrayList = [
    {
        name: "cookie Policy",
        onclick: () => console.log("Terms of Service")
    },
    {
        name: "cookie Policy",
        onclick: () => console.log("Terms of Service")
    },
    {
        name: "Privacy Policy",
        onclick: () => console.log("Terms of Service")
    },
]

ContentFooter.defaultProps = {
    ListContent1: {
        title: "La F3 española",
        content: arrayList
    },
    ListContent2: {
        title: "legal",
        content: arrayList
    },
    newsletter: {
        title: "Newsletter",
        content: "Suscríbete a nuestro newsletter y recibe las últimas noticias de la F3 española",
        placeholder: "Email",
        textButton: "subcribirse"
    }
}

ContentFooter.propTypes = {
    ListContent1: PropTypes.object,
    ListContent2: PropTypes.object,
    newsletter: PropTypes.object
}

import React from "react";
import "./listStanding.css";

import { StandingCell } from "../../../atoms/standingCell/StandingCell";
import { Text } from "../../../atoms/Text/Text";
import { FlagKit } from "../../../atoms/flagKit/FlagKit";

export const ListStanding = ({ round, orderBy ,drivers }) => {
  if (round["small-flag-icon"]) {
    round["smallFlag"] = JSON.parse(
      decodeURIComponent(round["small-flag-icon"])
    );
  }

  function getOrderBy(orderBy) {
    switch (orderBy) {
      case 0:
        return round.totalStanding.map((pilot, index) => {
          let driverStanding = round.standings.find(
            (element) => element.driverId == pilot
          );
          //console.log("driverStanding", driverStanding)
          if (driverStanding === undefined) {
            driverStanding = {
              driverId: pilot,
              BL1: false,
              R1: "-",
              BL2: false,
              R2: "-",
              BL3: false,
              R3: "-",
              Q1: "-",
              Q2: "-",
            };
          }
          return (
            <StandingCell
              key={index}
              Q1={!driverStanding?.Q1 ? 0 : driverStanding.Q1}
              R1={!driverStanding?.R1 ? 0 : driverStanding.R1}
              FL1={!driverStanding?.BL1 ? 0 : driverStanding.BL1}
              Q2={!driverStanding?.Q2 ? 0 : driverStanding.Q2}
              R2={!driverStanding?.R2 ? 0 : driverStanding.R2}
              FL2={!driverStanding?.BL2 ? 0 : driverStanding.BL2}
              R3={!driverStanding?.R3 ? 0 : driverStanding.R3}
              FL3={!driverStanding?.BL3 ? 0 : driverStanding.BL3}
              extraRace={round['third-race'].length && true}
            />
          );
        });
      case 1:
        return round.totalStandingTeams.map((team, index) => {
          return (
            <StandingCell
              type={"team"}
              key={index}
              R1={
                !round.totalPointsTeams[team].R1
                  ? 0
                  : round.totalPointsTeams[team].R1
              }
              R2={
                !round.totalPointsTeams[team].R2
                  ? 0
                  : round.totalPointsTeams[team].R2
              }
              R3={
                !round.totalPointsTeams[team].R3
                  ? 0
                  : round.totalPointsTeams[team].R3
              }
              extraRace={round['third-race'].length && true}
            />
          );
        });
      case 2:
        return round.rookiesStanding.map((rookieId, index) => {
          let driver = drivers.find(
            (element) => element.driverId == rookieId
          );
          if (driver === undefined) {
            driver = {
              driverName: "No pilot",
              driverNum: "No num",
              teamId: "No team",
              country: "No country",
            };
          }
          if(driver.rookies === false) return null
          if(round.rookiesPoints === undefined) return (
            <StandingCell
              type={"team"}
              key={index}
              R1={"-"}
              R2={"-"}
              R3={"-"}
              extraRace={round['third-race'].length && true}
            />
          )
          return (
            <StandingCell
              type={"team"}
              key={index}
              R1={
                round.rookiesPoints[rookieId] === undefined ? 0 : 
                // 1
                round.rookiesPoints[rookieId].R1
              }
              R2={
                round.rookiesPoints[rookieId] === undefined ? 0 : 
                // 2
                 round.rookiesPoints[rookieId].R2
              }
              R3={
                round.rookiesPoints[rookieId] === undefined ? 0 : 
                // 2
                 round.rookiesPoints[rookieId].R3
              }
              extraRace={round['third-race'].length && true}
            />
          );
        });
      default:
        return round.totalStanding.map((pilot, index) => {
          let driverStanding = round.standings.find(
            (element) => element.driverId == pilot
          );
          if (driverStanding === undefined) {
            driverStanding = {
              driverId: pilot,
              BL1: false,
              R1: "-",
              BL2: false,
              R2: "-",
              BL3: false,
              R3: "-",
              Q1: "-",
              Q2: "-",
            };
          }
          return (
            <StandingCell
              key={index}
              Q1={!driverStanding.Q1 ? 0 : driverStanding.Q1}
              R1={!driverStanding.R1 ? 0 : driverStanding.R1}
              FL1={!driverStanding.BL1 ? 0 : driverStanding.BL1}
              Q2={!driverStanding.Q2 ? 0 : driverStanding.Q2}
              R2={!driverStanding.R2 ? 0 : driverStanding.R2}
              FL2={!driverStanding.BL2 ? 0 : driverStanding.BL2}
              R3={!driverStanding.R3 ? 0 : driverStanding.R3}
              FL3={!driverStanding.BL3 ? 0 : driverStanding.BL3}
              extraRace={false}
            />
          );
        });
    }
  }
  return (
    <div className="listStandingContainer">
      <div className="listStandingHeader">
        <div className="listStandingHeaderInfo">
          <div className="listStandingNameRound">
            {round["smallFlag"] ? (
              <img src={round["smallFlag"].url} alt={round["smallFlag"].alt} />
            ) : (
              <FlagKit country={round.country ? round.country : null} />
            )}
            <Text size={"trackName-small"}>
              {round["circuit-display-name"]
                ? round["circuit-display-name"]
                : null}
            </Text>
          </div>
          <Text size={"p-small"}>
            {round["date-display-text"] ? round["date-display-text"] : null}
          </Text>
        </div>
        {orderBy === 0 ? (
          <div className="listStandingCellHeader">
            <div className="listStandingFR">Q1</div>
            <div className="listStandingSR">R1</div>
            <div className="listStandingFR">Q2</div>
            <div className="listStandingSR">R2</div>
            {round['third-race'].length ? <div className="listStandingSR">R3</div> : ''}
          </div>
        ) : (
          <div className="listStandingCellHeader TeamCellHeader">
            <div className="listStandingSR">R1</div>
            <div className="listStandingSR">R2</div>
            {round['third-race'].length ? <div className="listStandingSR">R3</div> : ''}
          </div>
        )}
      </div>
      {getOrderBy(orderBy)}
      <div className="bottomNav">
        {orderBy === 0 ? (
          <div className="listStandingCellHeader">
            <div className="listStandingFR">Q1</div>
            <div className="listStandingSR">R1</div>
            <div className="listStandingFR">Q2</div>
            <div className="listStandingSR">R2</div>
            {round['third-race'].length ? <div className="listStandingSR">R3</div> : ''}
          </div>
        ) : (
          <div className="listStandingCellHeader TeamCellHeader">
            <div className="listStandingSR">R1</div>
            <div className="listStandingSR">R2</div>
            {round['third-race'].length ? <div className="listStandingSR">R3</div> : ''}
          </div>
        )}
        <div className="listStandingNameRound">
          {round["smallFlag"] ? (
            <img src={round["smallFlag"].url} alt={round["smallFlag"].alt} />
          ) : (
            <FlagKit country={round.country ? round.country : null} />
          )}
          <Text size={"trackName-small"}>
            {round["circuit-display-name"]
              ? round["circuit-display-name"]
              : null}
          </Text>
        </div>
      </div>
    </div>
  );
};

ListStanding.defaultProps = {
  round: {
    circuit: "Barcelona",
    dataText: "13-15 may",
    ranking: [1, 2, 3, 4, 5, 6, 7],
    standingRound: [
      { driverId: 1, R1: 1, R2: 1, R3: 1 },
      { driverId: 2, R1: 2, R2: 2, R3: 2 },
      { driverId: 3, R1: 3, R2: 3, R3: 3 },
      { driverId: 4, R1: 4, R2: 4, R3: 4 },
      { driverId: 5, R1: 5, R2: 5, R3: 5 },
      { driverId: 6, R1: 6, R2: 6, R3: 6 },
      { driverId: 7, R1: 7, R2: 7, R3: 7 },
      { driverId: 8, R1: 8, R2: 8, R3: 8 },
    ],
  },
};

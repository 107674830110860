import React, { useEffect } from "react";
import "./newsSection.css";
import { NewsCard } from "../../molecules/cards/newsCard/NewsCard";
import resizeHandler from "../../../utils/sizeScreen";

export const NewsSection = ({ news, section = true }) => {
  const [size, setSize] = React.useState("");
  const sizeScreen = () => {
    setSize(resizeHandler());
  };
  useEffect(() => {
    sizeScreen();
  }, []);
  window.addEventListener("resize", sizeScreen);
  if (size === "small" && news.length >= 1) {
    return (
      <div
        className="uk-position-relative uk-visible-toggle uk-light sliderNews"
        tabIndex="-1"
        uk-slider="center: true"
      >
        <ul className="uk-slider-items uk-grid">
          {news.map((item, index) => {
            return (
              <li key={index} >
                <div className="uk-panel">
                  <NewsCard
                    className=""
                    image={item.image}
                    title={item.title}
                    slug={item.slug}
                    subtitle={item.subtitle}
                    categories={item.category}
                    date={item.date}
                    id={item.id}
                  />
                </div>
              </li>
            );
          })}
        </ul>
        <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
      </div>
    );
  }
  if(news.length >= 1){
  return (
    <div
      className={
        section ? "newsSectionGrid" : "newsPageGrid uk-container uk-margin-auto"
      }
    >
      {news.map((item, index) => {
        return (
          <NewsCard
            key={index}
            className={
              index === 0
                ? section
                  ? "firstNewsCard"
                  : "firstNewsCardPage"
                : "newsCard"
            }
            image={item.image}
            title={item.title}
            slug={item.slug}
            subtitle={item.subtitle}
            categories={item.category}
            date={item.date}
            size={index === 0 ? (section ? "full" : "latest") : "default"}
            id={item.id}
          />
        );
      })}
    </div>
  )}

  return <div>Emp</div>;
};

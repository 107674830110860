import React from 'react'
import './circuitCard.css'

import { Text } from '../../../atoms/Text/Text'
import { FlagKit } from '../../../atoms/flagKit/FlagKit'




export const CircuitCard = ({name,date,round,flag,country,imageBack,circuitImage,onclick}) => {
  return (
    <div onClick={onclick} className='circuitCard'>
        <div className='circuitCardRound'><span className='circuitCardRoundNumber'>{round}</span></div>
        <div className='circuitCardContainer'>
          <div 
            style={{backgroundImage: `url(${imageBack})` }}
            className={`circuitCardImage`}
          >
              <img src={circuitImage} alt=""/>
          </div>
          <div className='circuitCardContent'>
              <div className='circuitCardName'>
                {
                  flag? <img src={flag} alt=""/> :  <FlagKit country={country}/>
                }
                <Text size='trackName-small'>{name}</Text>
              </div>
              <Text size='b-medium' color='red'>{date}</Text>
          </div>
        </div>
    </div>
  )
}

CircuitCard.defaultProps = {
  name: 'barcelona',
  date: '12 nov',
  round: '1'
}

import React from "react";
import PropTypes from "prop-types";
import "./selector.css";

export const Selector = ({ arraySelect, callback ,returnValue,nameSelect}) => {
  const onChangeSelect = (e) => {
    callback(e.target.value);
  };
  return (
    <div className="selectContain">
      <select onChange={onChangeSelect} className="selector"  >
        {nameSelect && <option value="" disabled hidden>{nameSelect}</option>}
        {arraySelect.map((item, index) => (
          <option key={index} value={returnValue?item:index} data_index={index}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

Selector.defaultProps = {
  arraySelect: ["tag1", "tag2", "tag3"],
  callback: () => {},
  returnValue: true,
  nameSelect: null
};

Selector.propTypes = {
  arraySelect: PropTypes.arrayOf(PropTypes.string),
  callback: Function.prototype,
};
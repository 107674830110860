import React, { useEffect } from 'react'
import { AboutTemplate } from '../components/templets/aboutTemplate/AboutTemplate';
import { Helmet } from 'react-helmet';
import { getAbout, getFAQS } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import iconVB from "../assets/logos/eurocup3VB.png";


export const AboutPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);


    useEffect(() => {
    const getInfo = async () => {
        await getAbout(dispatch,state)
        await getFAQS(dispatch,state)
    }
    getInfo();
    },[])

    useEffect(() => {
        if(state.pages.about && state.pages.faqs){
          setLoading(false);
        }
      },[state,dispatch])
  
  

  return (
    <>
        {loading  ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        :
          <>
            <Helmet>
              <title>About - Eurocup 3</title>
              <meta name="description" content={'All about european F3 formula, availed by the Spanish racing association'}/>
              <link rel="canonical" href={document.location} />
              <meta name="robots" content="index, follow"/>
              <meta property="og:title" content={'About - Eurocup 3'}/>
              <meta property="og:type" content={"website"} />
              <meta property="og:image" content={iconVB} />
              <meta property="og:url" content={document.location} />
              <meta property="og:site_name" content="About the Eurocup 3 Championship" />
              <meta property="og:locale" content="en_GB" />
              <meta property="og:locale:alternate" content="en_US" />
            </Helmet>
            <AboutTemplate
              arrayFaqs={state.pages.about}
              arrayAcc={state.pages.faqs}
            />
          </>
            
        }
    </>
  )
}

import React from 'react'
import "./winRoundSection.css"
import { GrFormNext,GrFormPrevious} from "react-icons/gr";
import { WinPilotCard } from '../../molecules/cards/winPilotCard/WinPilotCard'


export const WinRoundSection = ({firstRace,secondRace,thirdRace}) => {
  return (
    <div className='uk-position-relative uk-light heightSectionWinRound' tabIndex="-1" uk-slider="clsActivated:uk-transition-active; center:true; finite:false; autoplay:true">

        <ul className='uk-slider-items uk-grid h_100'>
            <li className='uk-width-1-1 min-height: 300 h_100 winning-card'>
                <div className='uk-panel h_100'>
                    <div className='uk-text-center centerWinPilot'>
                        <WinPilotCard
                            nameRace={"first Race"}
                            namePilot={firstRace.namePilot}
                            countryPilot={firstRace.countryPilot}
                            teamPilot={firstRace.teamPilot}
                            timeLap={firstRace.timeLap}
                            carImage={firstRace.carImage?.url}
                            pilotImage={firstRace.pilotImage?.url}
                        />
                    </div>
                </div>
            </li>
            {secondRace['namePilot'] &&
                <li className='uk-width-1-1 h_100 winning-card'>
                    <div className='uk-panel h_100'>
                        <div className='uk-text-center centerWinPilot'>
                            <WinPilotCard
                                nameRace={"Second Race"}
                                namePilot={secondRace.namePilot}
                                countryPilot={secondRace.countryPilot}
                                teamPilot={secondRace.teamPilot}
                                timeLap={secondRace.timeLap}
                                carImage={secondRace.carImage?.url}
                                pilotImage={secondRace.pilotImage?.url}
                                reverse={true}
                            />
                        </div>
                    </div>
                </li>
            }
            {thirdRace['namePilot'] &&
                <li className='uk-width-1-1 h_100 winning-card'>
                    <div className='uk-panel h_100'>
                        <div className='uk-text-center centerWinPilot'>
                            <WinPilotCard
                                nameRace={"Third Race"}
                                namePilot={thirdRace.namePilot}
                                countryPilot={thirdRace.countryPilot}
                                teamPilot={thirdRace.teamPilot}
                                timeLap={thirdRace.timeLap}
                                carImage={thirdRace.carImage?.url}
                                pilotImage={thirdRace.pilotImage?.url}
                                reverse={true}
                            />
                        </div>
                    </div>
                </li>
            }
        </ul>

        <a className='uk-position-center-left uk-position-small nav-arrows' href="#" uk-slidenav-previous uk-slider-item="previous"><GrFormPrevious/></a>
        <a className='uk-position-center-right uk-position-small nav-arrows' href="#" uk-slidenav-next uk-slider-item="next"><GrFormNext/></a>
    </div>
  )
}


WinRoundSection.defaultProps = {
    firstRace: {
        namePilot: "Pilot Name",
        countryPilot: "Pilot Country",
        teamPilot: "Pilot Team",
        timeLap: "00:00:00",
        carImage: "/image/default/Car_empty.png",
        pilotImage: "/image/default/pilot_empty.png"
    },
    
    secondRace: {
        namePilot: "Pilot Name",
        countryPilot: "Pilot Country",
        teamPilot: "Pilot Team",
        timeLap: "00:00:00",
        carImage: "/image/default/Car_empty.png",
        pilotImage: "/image/default/pilot_empty.png"
    }
}

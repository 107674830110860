import React from "react";
import "./newsCard.css";
import { PropTypes } from "prop-types";
import { Category } from "../../../atoms/category/Category";
import { Text } from "../../../atoms/Text/Text";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../../../../utils/dateConvert";

export const NewsCard = ({
  image,
  title,
  subtitle,
  slug,
  categories,
  date,
  size,
  className,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/new/${slug}`);
        window.scrollTo({
          top: 0,
        });
      }}
      className={`newsCard ${size} ${className}`}
    >
      <div className="newsCard-contents">
        <div className="img-wrap">
          <img className="imageNews" src={image} alt={title} />
        </div>
        <div className="contentNewsCard">
          <div className="topContentNewsCard">
            <div className="topContentCategories">
              {
                Array.isArray(categories) &&
                  categories.map((item, index) => {
                    return <Category category={item.name} key={index} />;
                  })              
              }
            </div>
            
            <Text size="p-small" color={size === "full" ? "white" : "black"}>
              {convertDate(date)}
            </Text>
          </div>
          <div className="textNewsCard">
            <Text
              size={size === "full" ? "p-large" : "p"}
              color={size === "full" ? "white" : "black"}
              seo={"h2"}
            >
              {title}
            </Text>
          </div>
          {size === "latest" && (
            <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
          )}
        </div>
      </div>
    </div>
  );
};

NewsCard.defaultProps = {
  image: "",
  title: "title",
  subtitle: "subtitle",
  categories: [],
  date: "date",
  size: "default",
  className: "",
};

NewsCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  categories: PropTypes.array,
  date: PropTypes.string,
  size: PropTypes.oneOf(["default", "latest", "full"]),
  className: PropTypes.string,
};

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import {  useNavigate, useParams } from 'react-router-dom'
import { Grid } from 'uikit-react'
import { convertDate } from '../../../utils/dateConvert'
import { shareSocialLinks } from '../../../utils/shareSocialLinks'
import resizeHandler from '../../../utils/sizeScreen'
import { Category } from '../../atoms/category/Category'
import { Text } from '../../atoms/Text/Text'
import { SocialIcons } from '../../molecules/socialIcons/SocialIcons'
import { ErrorTemplate } from '../ErrorTemplate/ErrorTemplate'
import iconVB from "../../../assets/logos/eurocup3VB.png"
import './newTemplate.css'

const regex = /(<([^>]+)>)/gi;

export const NewTemplate = ({newList}) => {
  const { id } = useParams();
  const navigate = useNavigate();


  const [size, setSize] = React.useState("");
  const sizeScreen = () => { setSize(resizeHandler()) }
  useEffect(() => {
    sizeScreen();
  }, []);

window.addEventListener('resize', sizeScreen);
  const filterNew = newList.find((item) => item.slug == id);
  if(!filterNew) {
    return <ErrorTemplate/>
  }
  if(filterNew.positionImage === undefined) filterNew.positionImage = "center";

  console.log("filterNew - ", filterNew)

  return (
    <>
      <Helmet>
        <title>{filterNew.title} - Eurocup-3 News</title>
        <meta name="description" content={filterNew.subtitle.replace(regex,"").substring(0, 160)} />
        <link rel="canonical" href={document.location} />
        <meta name="robots" content="index, follow"/>
        <meta property="og:title" content={filterNew.title}/>
        <meta property="og:type" content={"profile"} />
        <meta property="og:image" content={filterNew.image} />
        <meta property="og:url" content={document.location} />
        <meta property="og:site_name" content={`${filterNew.title} Eurocup3`} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:locale:alternate" content="en_US" />
        <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
        <meta HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'/>
      </Helmet>

      <div>
        <div className='contentNewImage'>
          <img className='newFotoPrincipal' src={filterNew.image} alt={filterNew.title} style={{"object-position": filterNew.positionImage }}/>
          <div className='titleNew'>
            <Text size='h2' seo={"h1"} color='white'>{filterNew.title}</Text>
          </div>
        </div>
        <Grid className="uk-width-1-1 uk-width-4-5@m uk-margin-auto contentNew">
        <div className='uk-width-1-1'>
          <div className='uk-width-1-1 uk-width-3-5@s uk-margin-auto categoryDate' >
            <div className='categories'>
              {
                filterNew.category.map((item,index) => {
                  return (<Category key={index} category={item.name} onclick={()=>{
                    navigate("../news/"+item.slug)
                  }}/>)
                })
              }
            </div>
              <Text size='p-small'>{convertDate(filterNew.date)}</Text>
          </div>
        </div>
          <div className='uk-width-1-1 uk-width-1-5@s'>
            <div className='socialShare' uk-sticky={size == "small"?"position: bottom;":"end: !.contentNew; offset: 200"}>
              {size != "small" && <div>share</div>}
              <SocialIcons className={"socialShareIcons"} negative={false} arrayIcons={shareSocialLinks(window.location.href)}/>
            </div>
          </div>
          <div className='uk-width-4-5 uk-margin-auto uk-width-3-5@s '  dangerouslySetInnerHTML={{__html: filterNew.description}}></div>
          <div className='uk-width-1-5@s '></div>
        </Grid>
      </div>
    </>
  )
}

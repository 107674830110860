import React, { useEffect, useState } from "react";
import "./standingTable.css";
import { Text } from "../../atoms/Text/Text";
import { FcNext, FcPrevious } from "react-icons/fc";
import { ListStanding } from "../../molecules/lists/listStanding/ListStanding";
import elCapeonato from "../../../assets/image/image/fondo2.png";
import { PilotPointsStanding } from "../../atoms/pilotPointsStanding/PilotPointsStanding";
import { abbreviateName } from "../../../utils/stringUtils";
// import { getStandingForTeam } from "../../../utils/calculStandings";

export const StandingTable = ({ rounds, drivers, teams, orderBy, labels }) => {
  // getStandingForTeam(rounds, teams);
  const [infoRounds, setRounds] = useState(
    rounds.sort((a, b) => a["round-number"] - b["round-number"])
  );
  useEffect(() => {
    setRounds(rounds);
  }, [rounds, orderBy]);

  function getOrdeBy(orderBy) {
    switch (orderBy) {
      case 0:
        return (
          //drivers
          infoRounds[1].totalStanding.map((rankDriver, index) => {
            let driver = drivers.find(
              (element) => element.driverId == rankDriver
            );
            /* console.log("entra driver"); */
            if (driver === undefined) {
              driver = {
                driverName: "No pilot",
                driverNum: "No num",
                teamId: "No team",
                country: "No country",
                rookies: false,
              };
            }
            return (
              <PilotPointsStanding
                key={index}
                positionDriver={index + 1}
                nameDriver={abbreviateName(driver.driverName, true)}
                countryDriver={driver.country}
                pointsDriver={infoRounds[1].totalPoints[rankDriver]}
                isRookie={driver.rookies}
              />
            );
          })
        );
      case 1:
        return (
          //teams
          infoRounds[7].totalStandingTeams.map((rankDriver, index) => {
            let team = teams.find((element) => element.teamId == rankDriver);
            if (team === undefined) {
              team = {
                teamShortName: "No team",
                country: "JE",
              };
            }
            return (
              <PilotPointsStanding
                key={index}
                positionDriver={index + 1}
                nameDriver={team.teamName}
                countryDriver={team.country}
                pointsDriver={infoRounds[7].totalPointsTeams[rankDriver].total}
              />
            );
          })
        );
      case 2:
        return (
          //rookies

          infoRounds[0].rookiesStanding.map((rankDriver, index) => {
            let driver = drivers.find(
              (element) => element.driverId == rankDriver
            );
            if (driver === undefined) {
              driver = {
                driverName: "No pilot",
                driverNum: "No num",
                teamId: "No team",
                country: "No country",
                rookies: false,
              };
            }
            if (driver.rookies === false) return null
            let totalPoints = infoRounds.reduce((acc, round) => {
              if (round.rookiesPoints === undefined) return acc;
              if (driver.rookies === false) return acc;
              if (round.rookiesPoints[rankDriver] === undefined) return acc;
              return Number(acc) + Number(round.rookiesPoints[rankDriver].total);
            }, 0);
            return (
              <PilotPointsStanding
                key={index}
                positionDriver={index + 1}
                nameDriver={abbreviateName(driver.driverName, true)}
                countryDriver={driver.country}
                pointsDriver={totalPoints}
                isRookie={driver.rookies}
              />
            );
          })
        );
      default:
        return (
          //drivers
          infoRounds[1].totalStanding.map((rankDriver, index) => {
            let driver = drivers.find(
              (element) => element.driverId == rankDriver
            );
            if (driver === undefined) {
              driver = {
                driverName: "No pilot",
                driverNum: "No num",
                teamId: "No team",
                country: "No country",
                rookies: false,
              };
            }

            return (
              <PilotPointsStanding
                key={index}
                positionDriver={index + 1}
                nameDriver={abbreviateName(driver.driverName, true)}
                countryDriver={driver.country}
                pointsDriver={infoRounds[1].totalPoints[rankDriver]}
                isRookie={driver.rookies}
              />
            );
          })
        );
    }
  }

  return (
    <div className="standingsMainWrapper">
      <div className="standingTableAlign">
        <table className="uk-table totalPilotsStanding">
          <thead>
            <tr>
              <th>
                <Text color={"gray"} size={"p-small"}>
                  {labels[0]}
                </Text>
              </th>
              <th>
                <Text color={"gray"} size={"p-small"}>
                  {labels[1]}
                </Text>
              </th>
              <th>
                <Text color={"gray"} size={"p-small"}>
                  {orderBy === 0 ? labels[2] : labels[3]}
                </Text>
              </th>
              <th>
                <Text color={"gray"} size={"p-small"}>
                  {labels[4]}
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {getOrdeBy(orderBy)}
            <tr className="spacerCell">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div
          className="sliderPrincipal"
          uk-slider="clsActivated: uk-transition-active; finite: true"
        >
          <div className="uk-position-relative">
            <div className=" uk-light uk-slider-container" tabIndex="-1">
              <ul className=" uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@l uk-child-width-1-6@xl">
                {infoRounds.map((round, index) => {
                  return (
                    <li key={index}>
                      <img
                        src={elCapeonato}
                        width={150}
                        height={150}
                        className="imagenSlider"
                        alt="img"
                      />
                      <div className="uk-panel">
                        <ListStanding
                          round={round === "" ? undefined : round}
                          orderBy={orderBy}
                          drivers={drivers}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="uk-position-top-left"
                href="#"
                uk-slider-item="previous"
              >
                <FcPrevious />
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="uk-position-top-right" href="#" uk-slider-item="next">
                <FcNext />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StandingTable.defaultProps = {
  rounds: ["", "", "", "", ""],
};

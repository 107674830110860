import { PropTypes } from "prop-types";
import React from "react";
import { Text } from "../Text/Text";
import "./category.css";

export const Category = ({ category , onclick }) => {
  return (
    <div onClick={onclick} className="categoryTag">
      <Text size="p-small" color="white">
        {category}
      </Text>
    </div>
  );
};

Category.defaultProps = {
  category: "category",
  onclick: () => {},
};

Category.propTypes = {
  category: PropTypes.string,
  onclick: Function.prototype,
};

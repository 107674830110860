import React from 'react'
import './faqSection.css'
import { FaqCard } from '../../molecules/cards/faqCard/FaqCard'
import { Text } from '../../atoms/Text/Text'
import { Grid } from 'uikit-react'

export const FaqSection = ({arrayInfoFaqs}) => {
  return (
    <div className='faqContainer'>
        <Grid className='faqContent uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@l' >
            {
                arrayInfoFaqs.map((item,index) => {
                    return (
                        <FaqCard key={index}  image={item._embedded["wp:featuredmedia"]?item._embedded["wp:featuredmedia"][0].source_url:undefined} title={item.title.rendered} subtitle={item.subtitle} link={item.link} />
                    )
                })
            }
        </Grid>
    </div>
  )
}

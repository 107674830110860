import React from "react";
import "./teams.css";
import { PilotCard } from "../../molecules/cards/pilotCard/PilotCard";
import { TeamsHeader } from "../../molecules/teamsHeader/TeamsHeader";
import { Grid } from "uikit-react";

export const Teams = ({ team, pilots }) => {
  return (
    <div className="teamsContainer">
      <TeamsHeader
        color={team.color}
        logo={team.logo}
        carTeam={team.car ? team.car : undefined}
        button={team.name}
      />
      <Grid className="uk-child-width-1-2 uk-child-width-1-2@s uk-child-width-1-4@m contentTeams">
        {pilots.map((pilot, index) => {
          if (!pilot) return null;
          return (
            <div className="contentPilot">
              <PilotCard
                key={index}
                numPilot={pilot.driverNum}
                flagPilot={pilot.country}
                imgPilot={pilot.driverCardImage ? pilot.driverCardImage.url : undefined}
                namePilot={pilot.driverName}
                teamPilot={team.name}
                pilotId={pilot.driverId}
              />
            </div>

          );
        })}
      </Grid>
    </div>
  );
};

Teams.defaultProps = {
  team: {
    color: "red",
    logo: "logoTeam",
    car: "carTeam",
    button: { name: "button", onclick: () => {} },
  },
  pilots: [
    {
      num: 2,
      flag: "ES",
      img: "pilot",
      name: "Name Pilot",
      team: "Campos Racing",
    },
    {
      num: 2,
      flag: "US",
      img: "pilot",
      name: "Name Pilot",
      team: "Campos Racing",
    },
    {
      num: 2,
      flag: "IT",
      img: "pilot",
      name: "Name Pilot",
      team: "Campos Racing",
    },
    {
      num: 2,
      flag: "ES",
      img: "pilot",
      name: "Name Pilot",
      team: "Campos Racing",
    },
  ],
};

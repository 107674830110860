import React from 'react'
import './contactTemplate.css'
import { Logo } from '../../atoms/logo/Logo'
import { FormCRM } from '../../atoms/form/formCRM'

export const ContactTemplate = () => {
  return (
    <div className='contactTemplate'>
      <div className='contactImage'>
        <Logo color={"white"} className="logoContact"/>
      </div>
      <div className='contactForm'>
        <FormCRM/>
      </div>
    </div>
  )
}

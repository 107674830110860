import React from 'react'
import './verticalNavItem.css'
import {Text} from '../Text/Text'
import { PropTypes } from 'prop-types'
import resizeHandler from '../../../utils/sizeScreen'

export const VerticalNavItem = ({href,round,color}) => {
  return (
    <a href={href} className={`VerticalNavItem`}>
      <div className='verticalNavItemRound'>
        <Text size='b-small' color='white'>Round</Text>
      </div>
        <Text size='b-giant' color={color}>{round}</Text>
    </a>
  )
}

VerticalNavItem.defaultProps = {
  href: '#',
  round: '1',
  color: 'white'
}

VerticalNavItem.propTypes = {
  href: PropTypes.string,
  round: PropTypes.string
}
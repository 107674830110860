import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { NewsTemplate } from '../components/templets/newsTemplate/NewsTemplate';
import { getNews, getTags } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import { useParams } from 'react-router-dom';


export const NewsPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);



    useEffect(() => {
        const getInfo = async ()=> {
          await getNews(dispatch,state);
          await getTags(dispatch,state);
          setLoading(false);
        }
        getInfo();
      }, [])

      useEffect(() => {
        if(state.pages.news?.tags && state.pages.news?.news){
          setLoading(false);
        }
      },[state,dispatch])
      
  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        :
        <>
            <Helmet>
              <title>Eurocup-3 News</title>
              <meta name="description" content={"Latest news from the Eurocup-3 spanish formula 3 championship"} />
              <link rel="canonical" href={document.location} />
            </Helmet>
            <NewsTemplate
                news={state.pages.news.news}
                arrayTags={state.pages.news.tags}
            />
        </>
        }
    </>
  )
}

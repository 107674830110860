import React from 'react'
import './sideNavMenu.css'

import { PropTypes } from 'prop-types'
import { Text } from '../../../atoms/Text/Text'

export const SideNavMenu = ({title,arrayLinks,className=""}) => {
  return (
    <div className={`sideNavMenu ${className}`}>
        <Text size='s1' >{title}</Text>
        <ul className='uk-nav uk-nav-default contentSideNavMenu'>
        {
            arrayLinks.map((link,index) => {
            return (
                <li onClick = {link.onclick} key={index} className='sideNavActive'>
                    <Text  size='s2'  >{link.name}</Text>
                </li>
            )
            })
        }
        </ul>
    </div>
  )
}

SideNavMenu.defaultProps = {
  title: 'title',
  arrayLinks: [{name: 'El Campeonato', onclick: () => console.log('click')},{name: 'El motor', onclick: () => console.log('click')}]
}

SideNavMenu.propTypes = {
  title: PropTypes.string,
  arrayLinks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    onclick: PropTypes.func
  }))
}
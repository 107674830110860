import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { EuroCup3Context } from "./context/euroCup3/cup3State";
import { AboutPostsPage } from "./pages/AboutPostsPage";
import { CalendarPage } from "./pages/CalendarPage";
import { DefaultPage } from "./pages/DefaultPage";
import { HomePage } from "./pages/HomePage";
import { NewPage } from "./pages/newPage";
import { NewsPage } from "./pages/NewsPage";
import { TeamsPage } from "./pages/TeamsPage";
import { DrivePage } from "./pages/DriverPage";
import { LegalPostsPage } from "./pages/LegalPostsPage";
import { RoundDetailPage } from "./pages/RounDetailPage";
import { TeamPage } from "./pages/teamPage";
import { StandingPage } from "./pages/StandingPage";
import { BlogsPage } from "./pages/BlogsPage";
import { PostPage } from "./pages/postPage";
import { ContactPage } from "./pages/ContactPage";
import { AboutPage } from "./pages/AboutPage";
import { ErrorTemplate } from "./components/templets/ErrorTemplate/ErrorTemplate";
import { StreamPage } from "./pages/StreamPage";

function App() {
  return (
    <BrowserRouter>
      <EuroCup3Context>
        <DefaultPage>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="calendar/:year" element={<CalendarPage />} />
            <Route path="news" element={<NewsPage />} />
            <Route path="news/:tag" element={<NewsPage />} />
            <Route path="new/:id" element={<NewPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="about/:id" element={<AboutPostsPage />} />
            <Route path="teams" element={<TeamsPage />} />
            <Route path="teams/:teamId" element={<TeamPage />} />
            <Route path="driver/:driverId" element={<DrivePage />} />
            <Route path="legals/:id" element={<LegalPostsPage />} />
            <Route path="round-detail/:yearRound/:roundId" element={<RoundDetailPage />} />
            <Route path="standings" element={<StandingPage />} />
            <Route path="blog" element={<BlogsPage />} />
            <Route path="blog/:id" element={<PostPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="streams/:stream" element={ <StreamPage/> } />
            <Route path="*" element={<ErrorTemplate />} />
          </Routes>
        </DefaultPage>
      </EuroCup3Context>
    </BrowserRouter>
  );
}

export default App;



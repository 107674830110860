import React from 'react'
import './selectorTags.css'
import { Selector } from '../../atoms/selector/Selector'
import { Tags } from '../../atoms/tags/Tags'
import { propTypes } from 'prop-types'

export const SelectorTags = ({arraySelect,callback}) => {
    const [tags,setTags] = React.useState([])
    const onChange = (e) => {
        setTags([...tags,e])
        callback([...tags,e])
    }
    const deleteTags = (item) => {
        setTags(tags.filter((tag) => tag !== item))
        callback(tags.filter((tag) => tag !== item))
    }

  return (
    <div className='containerSelectTags'>
        <Selector arraySelect={arraySelect} callback={onChange} nameSelect={"All tags"}/>
        <div className='listOfTags'>
            {tags.map((item, index) => (
                <Tags key={index} tagName={item} deleteTag={()=>{deleteTags(item)}}/>
            ))}
        </div>
    </div>
  )
}

SelectorTags.defaultProps = {
    arraySelect: ['tag1','tag2','tag3'],
    callback: () => {}
}

// SelectorTags.propTypes = {
//     arraySelect: propTypes.array,
//     callback: Function.prototype
// }

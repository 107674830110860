import React from 'react'
import './timerComing.css'
import { PropTypes } from 'prop-types'

import { Text } from '../../atoms/Text/Text'
import arrowTimer from '../../../assets/iconsSVG/original/ArrowsTimer.svg'
import { countTimerDown } from '../../../utils/countTimer'
import { Buttons } from '../../atoms/button/Button'
import { FlagKit } from '../../atoms/flagKit/FlagKit'
import { useNavigate } from 'react-router-dom'

export const TimerComing = ({tiempo,smallFlag ,country,nameTrack,topSubtitle,countingTime,present,onlyLiveTrack}) => {
  const navigate = useNavigate()
  const [days, setDays] = React.useState(0)
  const [hours, setHours] = React.useState(0)
  const [minutes, setMinutes] = React.useState(0)
  const [seconds, setSeconds] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      var {days, hours, minutes, seconds} = countTimerDown(tiempo)
      setDays(days)
      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
    }, 1000);
    return () => clearInterval(interval);
  }, [tiempo]);
  return (
    <div className='timerContainer'>
        <div className='arrows'>
          <img src={arrowTimer} alt='arrows'/>
        </div>
        <div className='timerContent'>
          <div>
              <Text size='s2'>{topSubtitle}</Text>
              <div className='timerNameTrack'>
                  {smallFlag?<img src={smallFlag.url} alt={smallFlag.alt} />
                  :
                  <FlagKit country={country}/>
                  }
                  <Text size='trackName-small'>{nameTrack}</Text>
              </div>
          </div>
          {
          present?
          <Buttons onclick={()=>{
            onlyLiveTrack ?
              navigate("streams/live-track")
            :
              navigate("streams/live-youtube")
          }}>LIVE TIMMING</Buttons>
          :
          <div className='timerCount'>
            <div>
              <Text size='h5'>{days}</Text>
              <Text size='p-small caps'>{countingTime[0]}</Text>
            </div>
            <div>
              <Text size='h5'>{hours}</Text>
              <Text size='p-small caps'>{countingTime[1]}</Text>
            </div>
            <div>
              <Text size='h5'>{minutes}</Text>
              <Text size='p-small caps'>{countingTime[2]}</Text>
            </div>
            <div>
              <Text size='h5'>{seconds}</Text>
              <Text size='p-small caps'>{countingTime[3]}</Text>
            </div>
          </div>
          }
        </div>
    </div>
  )
}

TimerComing.defaultProps = {
  tiempo: "2023-06-01",
  country: "spain",
  nameTrack: "DONINGTON PARK",
  topSubtitle: "Coming event:",
  countingTime: ["days","hours","minutes","seconds"],
  present: false
}

TimerComing.propTypes = {
  tiempo: PropTypes.string,
  country: PropTypes.string,
  nameTrack: PropTypes.string,
  topSubtitle: PropTypes.string,
  countingTime: PropTypes.array
}
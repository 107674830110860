import React from 'react'
import './gallery.css'
import newsImage from "../../../assets/image/NewsImage.jpg";
import { FcNext,FcPrevious } from "react-icons/fc";
import { Cover } from 'uikit-react';

export const Gallery = ({images}) => {
  if(!images) return null
  return (
      <div className="uk-position-relative uk-visible-toggle uk-light " tabIndex="-1" uk-slider="clsActivated: uk-transition-active; center: true; autoplay: true; autoplay-interval: 3000;">
        <ul className="uk-slider-items uk-grid">
          {
            images.map((image,index) => {
            return (
              <li className="uk-width-1-1" key={index}>
                  <div className="uk-panel uk-cover-container" >
                      <Cover type="image" src={image}  alt={"gallery_image"}  className="imageGallery"/>
                  </div>
              </li>
            )
          })
        }
        </ul>
        <ul className="galleryNav uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"><FcPrevious/></a>
        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"> <FcNext/> </a>
      </div>

  )
}

Gallery.defaultProps = {
  images: []
}

import React, { useEffect } from "react";
import "./newsletterSub.css";
import { PropTypes } from "prop-types";
import { Text } from "../../atoms/Text/Text";
import { InputText } from "../../atoms/inputText/InputText";
import resizeHandler from "../../../utils/sizeScreen";
import { Buttons } from "../../atoms/button/Button";
import { useNavigate } from "react-router-dom";

export const NewsletterSub = ({
  title,
  content,
  placeholder,
  className = "",
  textButton,
}) => {
  const navigate = useNavigate()
  const [size, setSize] = React.useState("");
  const sizeScreen = () => {
    setSize(resizeHandler());
  };

  useEffect(() => {
    sizeScreen();
  }, []);

  window.addEventListener("resize", sizeScreen);

  return (
    <div className={`newsletterBlock ${className}`}>
      <Text size="h6" color="white">
        {title}
      </Text>
      <div className="newsletterContent">
        <Text size="s2" color="white">
          {content}
        </Text>
          <Buttons
          onclick={() => {
            navigate('/contact')
            window.scrollTo(0, 0);
          }}
            type="danger"
          >
            Register
          </Buttons>
      </div>
    </div>
  );
};

NewsletterSub.defaultProps = {
  title: "Newsletter",
  content: "content",
  placeholder: "Email",
  textButton: "subcribirse",
};

NewsletterSub.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  textButton: PropTypes.string,
};

import { LightboxItem } from "uikit-react"
import { abbreviateName } from "./stringUtils"



function convertToNumber(time){
  const s = time.replace(":", "")  
  const f = parseFloat(s) 
  return f
}


// get best lap for all races
export function bestLapRace(race,drivers){

  const best = race.reduce((acc,item)=>{
    if(item["completed-race"] === false) return acc
    if(item["time"] === null) return false
    const f = convertToNumber(item["time"])
    const s = convertToNumber(acc.time)
    if(f < s){
      acc = item
    }
    return acc
  },{"time":"100:00:00"})
  best["driver"] = drivers.find(pilot => {
    if(best["driver-id"] !== undefined) {
      return pilot.driverId == best["driver-id"]
    } else {
      return false
    }
  })
    return best
}

// how win the race
export const winRace = (race,drivers,teams)=>{
  let pilotStanding = {}
  let teamStanding = {}
  pilotStanding = drivers.find(pilot => {
    return pilot.driverId == race[0]?.["driver-id"]
  })
  if(pilotStanding === undefined){
    pilotStanding = {
      namePilot: "No pilot",
      countryPilot:"No country",
      teamPilot:"No team",
      timeLap:"No time",
      carImage:"/image/default/Car_empty.png",
      pilotImage:"/image/default/pilot_empty.png",
      teamColor:"#fff"
    }
  }else{
    teamStanding = teams.find(team => {
      return team.teamId == pilotStanding.teamId
    })
  }

  if(teamStanding === undefined){
    teamStanding = {
      teamName: "No team",
      teamColor: "#fff",
    }
  }
  const obj = {
    namePilot: pilotStanding.driverName ,
    countryPilot:pilotStanding.country,
    teamPilot:teamStanding.teamName,
    timeLap:race[0]?.["time"],
    carImage:teamStanding["car-image"],
    pilotImage:pilotStanding.driverCardImage,
    teamColor:teamStanding.teamColor
  }
  return obj
}


export const winStanding = (drivers,teams,rounds,actualRound)=>{
  if(drivers === undefined) return false

  // const afterRound = rounds.filter(round=>round["round-number"] <= actualRound["round-number"])
  const winers = Object.entries(actualRound["totalPoints"]).map(([key,value])=>{
    let driver
    let team
    driver = drivers.find(driver=>driver.driverId == key)
    if(driver === undefined){
      driver = {
        driverName: "No pilot",
        driverNum: "No num",
        teamId: "No team",
      }
    }else{
      team = teams.find(team=>team.teamId == driver.teamId)
    }
    if(team === undefined){
      team = {
        teamName: "No team",
        teamColor: "#fff",
      }
    }
    const obj = {
      id:key,
      name:abbreviateName(driver.driverName),
      numDriver:driver.driverNum,
      team:team.teamName,
      teamColor:team.teamColor,
      points:value
    }
    return obj
  })
  return winers.sort((a,b)=>b.points - a.points).splice(0,3)
}


 export const winSta = (drivers,teams,rounds,actualRound)=>{
  if(drivers === undefined) return false
  const afterRound = rounds.filter(round=>round["round-number"] <= actualRound["round-number"])
  const sumById = {};
  afterRound.forEach(round=>{
    round.rankDrivers.forEach((obj) => {
      const { driverId, total } = obj;
      if (!sumById[driverId]) {
        sumById[driverId] = 0;
      }
    sumById[driverId] += total;
    });
  })
  const winers = Object.entries(sumById).map(([key,value])=>{
    let driver
    let team
    driver = drivers.find(driver=>driver.driverId == key)
    if(driver === undefined){
      driver = {
        driverName: "No pilot",
        driverNum: "No num",
        teamId: "No team",
      }
    }else{
      team = teams.find(team=>team.teamId == driver.teamId)
    }
    if(team === undefined){
      team = {
        teamName: "No team",
        teamColor: "#fff",
      }
    }
    const obj = {
      id:key,
      name:abbreviateName(driver.driverName),
      numDriver:driver.driverNum,
      team:team.teamName,
      teamColor:team.teamColor,
      points:value
    }
    return obj
  })
  return winers.sort((a,b)=>b.points - a.points).splice(0,3)
}


//standings for teams
export const getStandingsTeams = (rounds,drivers,teams,year) => {
  /// comprobar que los datos esten
  if(rounds === undefined) return false
  if(drivers === undefined) return false
  if(teams === undefined) return false
  /// crear objeto para sumar puntos por equipos
  const sumForTeamTotal ={};
  rounds.forEach((round,index) => {
    /// crear objeto para sumar puntos por piloto y equipo
    const sumForTeam ={};
    const sumById = {};
    /// recorer los standigs de cada ronda
    round.standings.forEach((rankDriver,index)=>{
      //get driver form standings

      //sum or create object for team
        if (!sumById[rankDriver.driverId]) {
          sumById[rankDriver.driverId] = {R1: 0, BL1: 0, R2: 0, BL2: 0, R3: 0, BL3: 0,total: 0};
        }

      sumById[rankDriver.driverId].R1 += Number(rankDriver.R1);
      sumById[rankDriver.driverId].BL1 += Number(rankDriver.BL1);
      sumById[rankDriver.driverId].R2 += Number(rankDriver.R2);
      sumById[rankDriver.driverId].BL2 += Number(rankDriver.BL2);
      sumById[rankDriver.driverId].R3 += Number(rankDriver.R3);
      sumById[rankDriver.driverId].BL3 += Number(rankDriver.BL3);
    })
    Object.keys(round.totalPoints).map((key) => {
      
      if (!sumById[key]) {
        sumById[key] = {R1: 0, BL1: 0, R2: 0, BL2: 0, R3: 0, BL3: 0, total: 0};
      }
      if(isNaN(sumById[key].R1)) sumById[key].R1 = 0
      if(isNaN(sumById[key].R2)) sumById[key].R2 = 0
      if(isNaN(sumById[key].R3)) sumById[key].R3 = 0
      if(isNaN(sumById[key].BL1)) sumById[key].BL1 = 0
      if(isNaN(sumById[key].BL2)) sumById[key].BL2 = 0
      if(isNaN(sumById[key].BL3)) sumById[key].BL3 = 0
      sumById[key].total = sumById[key].R1 + sumById[key].R2 + sumById[key].R3 + sumById[key].BL1 + sumById[key].BL2 + sumById[key].BL3 ;

    })
    teams.forEach((teamItem)=>{
      if(teamItem["teamSeasonLineup"] === undefined) return
      const teamSeason = teamItem["teamSeasonLineup"].find((season)=> season.year == Number(year))
      teamSeason["ts-drivers"].forEach(element => {
        
        if(!sumForTeam[teamItem.teamId]) {
          sumForTeam[teamItem.teamId] = {R1: 0, BL1: 0, R2: 0, BL2: 0, R3: 0, BL3: 0,total: 0};
          
        }
        if(!sumForTeamTotal[teamItem.teamId] ){
          sumForTeamTotal[teamItem.teamId] = 0
        }
        if(sumById[element] === undefined)  return;
      sumForTeam[teamItem.teamId].R1 += Number(sumById[element].R1);
      sumForTeam[teamItem.teamId].BL1 += Number(sumById[element].BL1);
      sumForTeam[teamItem.teamId].R2 += Number(sumById[element].R2);
      sumForTeam[teamItem.teamId].BL2 += Number(sumById[element].BL2);
      sumForTeam[teamItem.teamId].R3 += Number(sumById[element].R3);
      sumForTeam[teamItem.teamId].BL3 += Number(sumById[element].BL3);
      sumForTeamTotal[teamItem.teamId] += Number(sumById[element].total);
    });
    if(index === 7){

      if(teamSeason["team-remove-points"]) {

        sumForTeamTotal[teamItem.teamId] -= teamSeason["team-remove-points"]
      }
      if(teamSeason["team-add-points"]) {

        sumForTeamTotal[teamItem.teamId] += teamSeason["team-add-points"]
      }

    }
    sumForTeam[teamItem.teamId].total = sumForTeamTotal[teamItem.teamId]
    })
    const sumByIdSort = Object.keys(sumForTeam).sort((a, b) => sumForTeam[b].total - sumForTeam[a].total);
    round["totalPointsTeams"]= sumForTeam;
    round["totalStandingTeams"]= sumByIdSort;
  });



  return rounds;
}


export const getStandingForTeam = (rounds,teams,year) => {
  // console.log("rounds",rounds);
  // console.log("teams",teams);
  // console.log("year",year);
  teams.map(team => {
    
    // const teamPoints = team.drivers.map(driver=>{
      if(team["teamSeasonLineup"] === undefined) return
      const teamSeason = team["teamSeasonLineup"].find((season)=> {

        return season.year === Number(year)
      })
      if(teamSeason === undefined) {
        if(rounds[0]["totalPointsTeams"] === undefined) rounds[0]["totalPointsTeams"] = {}
        rounds[0]["totalPointsTeams"][team.teamId]=  0
        return
      }
      const teamPoints = (teamSeason["ts-drivers"] !== '' && teamSeason["ts-drivers"].length > 0) && teamSeason["ts-drivers"].map(element => {
      const obj ={
        driverId:element,
        R1:[],
        R2:[],
        R3:[],
        BL1:[],
        BL2:[],
        BL3:[],
      }
      rounds.map(round => {
        const driverStanding = round.standings.find(standing=>{
          if(standing.driverId == element){  
            if(standing.R1 == undefined)  standing.R1 = 0
            if(standing.R2 == undefined)  standing.R2 = 0
            if(standing.R3 == undefined)  standing.R3 = 0
            if(standing.BL1 == undefined)  standing.BL1 = 0
            if(standing.BL2 == undefined)  standing.BL2 = 0
            if(standing.BL3 == undefined)  standing.BL3 = 0
            obj.R1.push(standing.R1)
            obj.R2.push(standing.R2)
            obj.R3.push(standing.R3)
            obj.BL1.push(standing.BL1)
            obj.BL2.push(standing.BL2)
            obj.BL3.push(standing.BL3)
            return obj
          }
        })
        if(driverStanding === undefined){
          obj.R1.push(0)
          obj.R2.push(0)
          obj.R3.push(0)
          obj.BL1.push(0)
          obj.BL2.push(0)
          obj.BL3.push(0)
        }
      })
      return obj
    })
    const arrayPointsR1 = [[],[],[],[],[],[],[],[]]
    const arrayPointsR2 = [[],[],[],[],[],[],[],[]]
    const arrayPointsR3 = [[],[],[],[],[],[],[],[]]
    const totalPoints = {}
    teamPoints.length > 0 && teamPoints.map(driver=>{
      for (let index = 0; index < driver.R1.length; index++) {
        arrayPointsR1[index].push(driver.R1[index] + driver.BL1[index]);
        arrayPointsR2[index].push(driver.R2[index] + driver.BL2[index]);
        arrayPointsR3[index].push(driver.R3[index] + driver.BL3[index]);
        
      }
    })
    for (let index = 0; index < arrayPointsR1.length; index++) {

      arrayPointsR1[index].sort((a,b)=>b-a);
      arrayPointsR1[index] = arrayPointsR1[index].splice(0,2);
      arrayPointsR1[index] = arrayPointsR1[index].reduce((a, b) => a + b, 0);

      arrayPointsR2[index].sort((a,b)=>b-a);
      arrayPointsR2[index] = arrayPointsR2[index].splice(0,2);
      arrayPointsR2[index] = arrayPointsR2[index].reduce((a, b) => a + b, 0);

      arrayPointsR3[index].sort((a,b)=>b-a);
      arrayPointsR3[index] = arrayPointsR3[index].splice(0,2);
      arrayPointsR3[index] = arrayPointsR3[index].reduce((a, b) => a + b, 0);


      totalPoints[`round${index+1}`] = {R1:arrayPointsR1[index] , R2:arrayPointsR2[index], R3:arrayPointsR3[index], total:arrayPointsR1[index]+arrayPointsR2[index]+arrayPointsR3[index] + Number(totalPoints[`round${index}`]?.total || 0) }
    }
    // console.log("team3",team);
    if(teamSeason["team-remove-points"] && totalPoints[`round8`] ) {
      totalPoints[`round8`].total -= teamSeason["team-remove-points"]
    }
    if(teamSeason["team-add-points"] && totalPoints[`round8`] ) {
      totalPoints[`round8`].total += teamSeason["team-add-points"]
    }
      

    // console.log("totalPoints",totalPoints);
    rounds.map(round=>{
      if(round["totalPointsTeams"] === undefined) round["totalPointsTeams"] = {}
      round["totalPointsTeams"][team.teamId]=  totalPoints[`round${round["round-number"]}`]
    })
})
  const sortStanding = Object.keys(rounds[rounds.length -1]["totalPointsTeams"]).sort((a,b)=>{
    return rounds[rounds.length -1]["totalPointsTeams"][b].total - rounds[rounds.length -1]["totalPointsTeams"][a].total
  });

  rounds.map(round=>{
    round["totalStandingTeams"] = sortStanding
  })
  return rounds;
}
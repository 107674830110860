import React, { useEffect, useState } from 'react'
import './teamsTemplate.css'
import text from '../../../assets/data/staticText.json'
import { Teams } from '../../organisms/teams/Teams'
import { Text } from '../../atoms/Text/Text'
import { Grid } from 'uikit-react'
import f1 from '../../../assets/image/F1.png'
import f2 from '../../../assets/image/F2.png'
import f3 from '../../../assets/image/F3.png'
import f4 from '../../../assets/image/F4.png'

export const TeamsTemplate = ({ teams, allDrivers, year }) => {
  const { teamsDrivers } = text

  return (
    <div>
      <div className='titleTeamsTemplate'>
        <Text size={"h2"} seo='h1' color="red">{year ? year : "----"}</Text>
        <Text size={'h2'} seo='h1' >{teamsDrivers.titleS1}</Text>
      </div>
      <div className='teamsTemplate'>
        {allDrivers !== undefined &&
          teams.map((team, index) => {
            return (
              <div key={index} className='team-item-wrap'>
                <TeamItem team={team} year={year} allDrivers={allDrivers} />
              </div>
            )
          })
        }
      </div>
      <div className='otherFormula'>
        <Text size={"h2"}>{teamsDrivers.titleS2}</Text>
        <Grid className="uk-child-width-1-4@s">
          <img src={f1} alt="f1" onClick={() => { window.location = "https://www.formula1.com/" }} />
          <img src={f2} alt="f2" onClick={() => { window.location = "https://www.fiaformula2.com/" }} />
          <img src={f3} alt="f3" onClick={() => { window.location = "https://www.fiaformula3.com/" }} />
          <img src={f4} alt="f4" onClick={() => { window.location = "https://f4spain.org/" }} />
        </Grid>
      </div>
    </div>
  )
}

function TeamItem({ team, year, allDrivers }) {
  const [isLoading, setIsLoading] = useState(false)
  const [driversTeam, setDriversTeam] = useState([])

  useEffect(() => {
    const fetchDrivers = async () => {
      setIsLoading(true)
      const teamSeason = team["teamSeasonLineup"].find((season) => {
        return season.year === Number(year)
      })
      try {
        const driversTeam = await teamSeason['ts-drivers']
        let drivers = []
        driversTeam.forEach((driverId) => {
          const driver = allDrivers.find((driver) => driver.driverId === Number(driverId))
          drivers.push(driver)
        })
        setDriversTeam(drivers)
      } catch (error) {
        console.log('Get ts-drivers error:', error);
      } finally {
        setIsLoading(false)
      }
    }
    fetchDrivers()
  }, [allDrivers, team, year])

  const teamInfo = {
    color: team.teamColor,
    logo: team.teamImage.url,
    car: team["car-image"].url,
    name: team.teamName
  }

  return (isLoading) ? <><div className='loadingNotice'>Loading...</div><Teams team={teamInfo} pilots={driversTeam} /></> : <Teams team={teamInfo} pilots={driversTeam} />
}
import React from "react";
import { Text } from "../../atoms/Text/Text";
import "./trackDetail.css";
import { convertTime } from "../../../utils/dateConvert";
import { HorariosTable } from "../horariosTable/HorariosTable";
import { convertRaceDate } from "../../../utils/dateConvert"

export const TrackDetail = ({ title, detailsArray,circuit }) => {

  console.log('race1-:', !convertRaceDate(circuit["race_horario_1"]))
  return (
    <div className="trackDetail">
      {(circuit.status !== 'past' && convertRaceDate(circuit["race_horario_1"])) &&
        <HorariosTable circuit={circuit}/>
      }

      {(!circuit["practice_horario_1"]  && !circuit["qualy_horario_1"]  && !circuit["race_horario_1"] || circuit.status == 'past' || !convertRaceDate(circuit["race_horario_1"])) &&
        <div>
          <Text size="h5" color="white">
            {title}
          </Text>
          <div className="trackDetailContent">
            {detailsArray.map((detail, index) => {
              return (
                <div
                  key={index}
                  className={`trackDetailContentItem ${
                    index >= detailsArray.length - 1 ? "lastItemTrackDetail" : ""
                  }`}
                >
                  <Text size="c1" color="white">
                    {detail.name}
                  </Text>
                  <Text size="c1" color="white">
                    {detail.value}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
};

TrackDetail.defaultProps = {
  title: "Details of the track",
  detailsArray: [
    { name: "Opened", value: "1921" },
    { name: "Length", value: "1921" },
    { name: "Turns", value: "1921" },
    { name: "Time Zone", value: "1921" },
    { name: "Lap Record", value: "1921" },
  ],
};

import React, { useEffect } from "react";
import "./secondBar.css";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Logo } from "../../../atoms/logo/Logo";
import { NavItem } from "../../../atoms/navItem/NavItem";
import { Icons } from "../../../atoms/icons/Icons";
import resizeHandler from "../../../../utils/sizeScreen";
import { OffCanvas } from "../offCanvas/OffCanvas";
import * as UIkit from 'uikit';
import { Text } from "../../../atoms/Text/Text";
import { config } from "../../../../utils/configAPI";

export const SecondBar = ({ arrayNav,seasons,season}) => {
  const [size, setSize] = React.useState("");
  const sizeScreen = () => {
    setSize(resizeHandler());
  };
  const navigate = useNavigate();
  useEffect(() => {
    sizeScreen();
  }, []);
  window.addEventListener("resize", sizeScreen);
  return (
    <div className="secondBarConatiner">
      <div className="secondBarContent">
        {size === "small"||size === "medium" ? (
          <>
            <Icons
              icon="menu"
              onclick={() => {
                UIkit.offcanvas("#offcanvas-nav").show();
              }}
            />
            <OffCanvas id={"offcanvas-nav"}>
              <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
                {arrayNav.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="uk-active liOffCanvas"
                      onClick={() => {
                        navigate(item.link);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <a className="uk-offcanvas-close"  uk-close>
                        <Text size="p" color="white">
                        {item.name}
                        </Text>
                      </a>
                    </li>
                  );
                })}
                <hr></hr>
                <li className="uk-active liOffCanvas"><a className="uk-offcanvas-close"  uk-close onClick={()=>{navigate("streams/live-track")}} ><div className="p white">LIVE TIMING</div></a></li>
                <li className="uk-active liOffCanvas"><a className="uk-offcanvas-close"  uk-close onClick={()=>{navigate("streams/live-youtube")}} ><div className="p white">STREAMING</div></a></li>
                
              </ul>
            </OffCanvas>
          </>
        ):null}
        <div className="navbar-logo">
          <Logo
            color="black"
            horizontal={true}
            isoType={true}
            onClick={() => {
              navigate("/");
              window.scrollTo({
                top: 0, 
                behavior: 'smooth'
              });
            }}
          />
        </div>
        {size !== "small" && size !== "medium" ? (
          <div className="secondBarMenu">
            {arrayNav.map((item, index) => {
              return (item.name === "Calendar" ? 
                  (<div className='uk-inline' key={index}>
                      <div className='navItem' onClick={()=>{navigate(item.link+"/"+season)}} ><Text size="b-medium">{item.name}</Text></div>
                      <div className='uk-dropdown' uk-dropdown="offset: 20">
                          <ul className='uk-nav uk-dropdown-nav seasons-dropdown'>
                              {
                                seasons.map((subItem, index) => {
                                  return  <li key={index}><a href={`/calendar/${subItem.name}`}>{subItem.name}</a></li>
                                })
                              }
                          </ul>
                      </div>
                  </div>)

                  :
                  (<NavItem
                  key={index}
                  name={item.name}
                  link={() => {
                    navigate(item.link);
                    window.scrollTo({
                      top: 0, 
                      behavior: 'smooth'
                    });
                  }}
                  seoLink={`http://eurocup3.org${item.link}`}
                />)
              );
            })}
          </div>
        ) : (
          <Icons icon="account" onclick={() => {
            window.location.href = config("production").URL+"/intranet/"
          }}/>
        )}
      </div>
    </div>
  );
};

SecondBar.defaultProps = {
  arrayNav: [
    { name: "Inicio", link: "/" },
    { name: "Noticias", link: "/noticias" },
    { name: "Calendario", link: "/calendario" },
    { name: "Clasificación", link: "/clasificacion" },
  ],
};

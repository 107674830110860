import React, { useEffect } from 'react'
import { FirstBar } from '../../molecules/firstBar/FirstBar'
import { SecondBar } from '../../molecules/navigation/secondBar/SecondBar'
import resizeHandler from '../../../utils/sizeScreen'
import { TestDev } from '../../../utils/configAPI'

export const NavBar = ({streams, arrayNav,socialIcons,seasons,season}) => {
  const [size, setSize] = React.useState("");
  const sizeScreen = () => { setSize(resizeHandler()) }

window.addEventListener('resize', sizeScreen);
  return (
    <div uk-sticky="start: 200; animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-light">
      <nav className="uk-navbar-container" uk-navbar>
        <div>
            {size === "small"||size === "medium" ? null:<FirstBar present={streams.live}  arrayIcons={socialIcons}/>}
            <SecondBar arrayNav={arrayNav} seasons={seasons} season={season}/>
        </div>
      </nav>
      {TestDev && <div style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}}>TEST ENVIRONMENT - DO NOT DEPLOY</div>}
    </div>
  )
}

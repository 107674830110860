import React, { useEffect, useState } from 'react'
import './standingTemplate.css'
import text from '../../../assets/data/staticText.json' 
import { StandingTable } from '../../organisms/standingTable/StandingTable'
import { PilotPointsStanding } from '../../atoms/pilotPointsStanding/PilotPointsStanding'
import { Text } from '../../atoms/Text/Text'
import { NavTableResults } from '../../molecules/navigation/NavTableResults/NavTableResults'
import { getStandingForTeam, getStandingsTeams } from '../../../utils/calculStandings'

export const StandingTemplate = ({rounds,drivers,teams,year,setYear,seasons}) => {
  const {standings} = text
  const [active, setActive] = useState(0);
  const [roundsTeams, setRoundsTeams] = useState(null);
  
  useEffect(() => {
    const selectedYear = seasons.find((season)=> season.id === Number(year))
    if(rounds === undefined) return
    if(selectedYear === undefined) return;
    if(teams === undefined) return;
    setRoundsTeams(getStandingForTeam(rounds,teams,selectedYear.name))
  }, [rounds,teams,year,seasons])
  
  if (!teams) return null;

  return (
    <div className='standingTempContainer'>
      <div className='standingTempAlign'>
        <div className='standingTempTitle'>
          <div className='uk-flex'>
            <h1>{standings.titleS1}</h1>
            <select onChange={(e)=>{setYear(e.target.value);}}>
              {
                seasons.map((season,index)=>(
                  <option key={index} value={season.id} selected={year == season.id}>{season.name}</option>
                ))
              }
            </select>
          </div>
        </div>
        <NavTableResults 
          responsive={false}
          categories={standings.Nav}
          callback={setActive}
        />
      {roundsTeams && <StandingTable rounds={roundsTeams}  drivers={drivers} teams={teams} orderBy={active} labels={standings.labels}/>}
      </div>
    </div>
  )
}




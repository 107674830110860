import React from 'react'
import './statsEuroCup3.css'
import { Text } from '../Text/Text'

export const StatsEuroCup3 = ({year,title}) => {
  return (
    <div className='statsEuroCupContainer'>
      <Text size={"heroSmall"} color="white" >{year}</Text>
      <div className='backgroundStats'><Text size={"s2"}>{title}</Text></div>
    </div>
  )
}

StatsEuroCup3.defaultProps = {
  year: "2023",
  title: "Stats for Eurocup 3"
}
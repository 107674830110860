import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { TeamsTemplate } from '../components/templets/teamsTemplate/TeamsTemplate';
import { getDrivers, getTeams, getYear } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import iconVB from "../assets/logos/eurocup3VB.png";


export const TeamsPage = () => {
  const [state, dispatch] = useCup3();
  const [loading, setLoading] = React.useState(true);
  const [year, setYear] = React.useState(state.season);

  useEffect(() => {
    const getInfo = async () => {
      await getDrivers(dispatch, state, year);
      await getTeams(dispatch, state, year);
      setLoading(false);
    }
    getInfo();
  }, [dispatch, state, year])

  useEffect(() => {
    if (state.pages.drivers?.[year] && state.pages.teams?.[year]) {
      setLoading(false);
    }
  }, [state, dispatch, year])

  return (
    <>
      {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : <>
          <Helmet>
            <title>Eurocup 3 2024 Teams lineup</title>
            <meta name="description" content={'Season 2024 teams lineup for the european F3 formula, availed by the Spanish racing association'} />
            <link rel="canonical" href={document.location} />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content={'2024 season team lineup Eurocup 3 Championship'} />
            <meta property="og:type" content={"website"} />
            <meta property="og:image" content={iconVB} />
            <meta property="og:url" content={document.location} />
            <meta property="og:site_name" content="Eurocup 3 teams lineup for the 2024 season" />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:locale:alternate" content="en_US" />
          </Helmet>
          <TeamsTemplate teams={state.pages.teams?.[year]} allDrivers={state.pages.drivers?.[year]} year={state?.year} />
        </>
      }
    </>
  )
}
import React from "react";
import "./navItem.css";
import selector from "../../../assets/iconsSVG/original/selecorNav.svg";
import { Text } from "../Text/Text";
import { PropTypes } from "prop-types";
import { Link } from 'react-router-dom';

export const NavItem = ({ link, name, seoLink }) => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <div className="navItemWrap">
      <Link
        to={`${seoLink}`}
        className="seoItem"
        aria-current="page"
      >
        {name}
      </Link>
      <div
        className="navItem"
        onClick={link}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <Text size="b-medium">{name}</Text>
        {isShown && <img className="selectorNavItem" src={selector} alt={""} />}
      </div>
    </div>
  );
};

NavItem.defaultProps = {
  link: () => {},
  name: "name",
};

NavItem.propTypes = {
  link: Function.prototype,
  name: PropTypes.string,
};

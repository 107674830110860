import React from "react";
import "./listCircuits.css";
import { PropTypes } from "prop-types";
import { CircuitCard } from "../../cards/circuitCard/CircuitCard";

export const ListCircuits = ({ array }) => {
  return (
    <div
      className="listCardCircuits uk-visible-toggle uk-light"
      tabIndex="-1"
      uk-slider="center: true; autoplay: true; autoplay-interval: 10000;"
    >
      <ul className="uk-slider-items uk-child-width-1-3 uk-child-width-1-6@m uk-grid">
        {array.map((circuit, index) => {
          return (
            <li key={index}>
              <div className="uk-panel">
                <CircuitCard
                    name={circuit.name}
                    date={circuit.date}
                    round={circuit.round}
                    imageBack={circuit.circuitImageBack.url}
                    circuitImage={circuit.circuitImageSmall.url}
                    country={circuit.country}
                    flag={circuit.smallFlag.url}
                    onclick={circuit.onclick}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ListCircuits.propTypes = {
  array: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      date: PropTypes.string,
      round: PropTypes.number,
      onclick: PropTypes.func,
    })
  ),
};

import React, { useEffect } from "react";
import "./roundDetailTemplate.css";
import text from "../../../assets/data/staticText.json";
import { ListNews } from "../../molecules/lists/listNews/ListNews";
import { NavTableResults } from "../../molecules/navigation/NavTableResults/NavTableResults";
import { TableResults } from "../../molecules/tableResults/TableResults";
import { CircuitSection } from "../../organisms/circuitSection/CircuitSection";
import { RoundInfo } from "../../organisms/roundInfo/RoundInfo";
import { Text } from "../../atoms/Text/Text";
import { WinRoundSection } from "../../organisms/winRoundSection/WinRoundSection";
import { RoundNav } from "../../molecules/navigation/roundNav/RoundNav";
import { bestLapRace, winRace, winSta } from "../../../utils/calculStandings";
import { convertToSlug } from "../../../utils/stringUtils";
import { useNavigate } from "react-router-dom";

export const RoundDetailTemplate = ({
  circuits,
  news,
  roundId,
  drivers,
  teams,
  rounds,
  season
}) => {
  const { round } = text;

  const navigator = useNavigate()

  const [id, setId] = React.useState(roundId);
  const [actualRound, setActualRound] = React.useState(
    rounds.find((round) => convertToSlug(round["circuit-display-name"]) == id)
  );
  
  const cancelledRaces = [
    actualRound['p1cancelledevent'] ? actualRound['p1cancelledevent'] : false, 
    actualRound['p2cancelledevent'] ? actualRound['p2cancelledevent'] : false,
    actualRound['q1cancelledevent'] ? actualRound['q1cancelledevent'] : false,
    actualRound['r1cancelledevent'] ? actualRound['r1cancelledevent'] : false,
    actualRound['q2cancelledevent'] ? actualRound['q2cancelledevent'] : false,
    actualRound['r2cancelledevent'] ? actualRound['r2cancelledevent'] : false,
    actualRound['r3cancelledevent'] ? actualRound['r3cancelledevent'] : false
  ]

  const showThirdRace =  actualRound['display-on-standings'] ? true : false

  const [prevRound, setPrevRound] = React.useState(null);
  const [nextRound, setNextRound] = React.useState(null);
  useEffect(() => {
    setActualRound(
      rounds.find((round) => convertToSlug(round["circuit-display-name"]) == id)
    );
    setPrevRound(
      rounds.find(
        (round) => round["round-number"] == actualRound["round-number"] - 1
      )
    );
    setNextRound(
      rounds.find(
        (round) => round["round-number"] == actualRound["round-number"] + 1
      )
    );
  }, [actualRound, rounds, id]);
  const [active, setActive] = React.useState(0);
  function tableRace(race) {
    switch (Number(race)) {
      case 0:
        return actualRound["practice-1"];
      case 1:
        return actualRound["practice-2"];
      case 2:
        return actualRound["qualification-race-1"];
      case 3:
        return actualRound["first-race"];
      case 4:
          return actualRound["qualification-race-2"];
      case 5:
        return actualRound["second-race"];
      case 6:
        return actualRound["third-race"];
      default:
        return actualRound["first-race"];
    }
  }

  if (!actualRound) {
    window.location.href = "/";
  }

  const bestLap = {
    "first-race": bestLapRace(actualRound["first-race"], drivers),
    "second-race": bestLapRace(actualRound["second-race"], drivers),
    "third-race": bestLapRace(actualRound["third-race"], drivers),
    "qualification-race-1": bestLapRace(
      actualRound["qualification-race-1"],
      drivers
    ),
    "qualification-race-2": bestLapRace(
      actualRound["qualification-race-2"],
      drivers
    ),
  };
  let latestRound = rounds.reverse().find((round) => round["status"] == "past");
  if (!latestRound) {
    latestRound = rounds[rounds.length - 1];
  }

  return (
    <div className="roundDetailTemplate">
      <RoundInfo
        numRound={actualRound["round-number"]}
        date={actualRound["date-display-text"]}
        weather={actualRound["weather"]}
        podcast={actualRound["spotify-link"]}
        standing={winSta(drivers, teams, rounds, actualRound)}
        bestsLaps={bestLap}
        latestRound={latestRound["round-number"]}
        extraRace={showThirdRace}
      />
      <CircuitSection
        roundDetail={true}
        list={false}
        circuits={circuits.sort((a, b) => a.round - b.round)}
        roundCircuit={actualRound["round-number"]}
      />
      {actualRound.status == "past" && (
        <WinRoundSection
          firstRace={winRace(actualRound["first-race"], drivers, teams)}
          secondRace={winRace(actualRound["second-race"], drivers, teams)}
          thirdRace={winRace(actualRound["third-race"], drivers, teams)}
        />
      )}
      <section className="roundDetailS3">
        <div className="roundDetailS3Title">
          <Text size={"h2"} seo="h2">
            {round.titleS3}
          </Text>
          <NavTableResults
            responsive={true}
            categories={showThirdRace ? [...round.races, ...round.extraRaces] : round.races}
            callback={setActive}
          />
        </div>

       
          <TableResults
            results={tableRace(active)}
            pilots={drivers}
            teams={teams}
            isCancelled={cancelledRaces[active]}
            extraRace={showThirdRace}
          />

      </section>
      <ListNews news={news} categories={[actualRound["circuit-full-name"]]} />
      <RoundNav prev={prevRound} next={nextRound} callback={setId} season={season} />
    </div>
  );
};

import React from "react";
import "./logo.css";
import PropTypes from "prop-types";

import isoTypeIcon from "../../../assets/logos/Isotype.png";
import iconHB from "../../../assets/logos/eurocup3HB.png";
import iconHW from "../../../assets/logos/eurocup3HW.png";
import iconVB from "../../../assets/logos/eurocup3VB.png";
import iconVW from "../../../assets/logos/eurocup3VW.png";

export const Logo = ({ isoType, horizontal, color, ...arg }) => {
  const logos = {
    black: horizontal ? iconHB : iconVB,
    white: horizontal ? iconHW : iconVW,
  };

  return (
    <img
      className="LogoEuroCup"
      src={isoType ? isoTypeIcon : logos[color]}
      alt="logo"
      {...arg}
    />
  );
};

Logo.defaultProps = {
  isoType: false,
  horizontal: false,
  color: "black",
};

Logo.propTypes = {
  isoType: PropTypes.bool,
  horizontal: PropTypes.bool,
  color: PropTypes.oneOf(["black", "white"]),
};

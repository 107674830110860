import React, { useEffect } from 'react'
import { NewTemplate } from '../components/templets/newTemplate/NewTemplate';
import { getNews } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';



export const NewPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const getInfo = async ()=> {
          await getNews(dispatch,state);
        }
        getInfo();
    }, [])

    useEffect(() => {
      if(state.pages.news){
        setLoading(false);
      }
    },[state,dispatch])

  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : 
          <NewTemplate
              newList={state.pages.news.news}
          />
        }
    </>
  )
}

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { RoundDetailTemplate } from '../components/templets/roundDetailTemplate/RoundDetailTemplate';

import {  getRounds, getStreams} from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import { Streams } from '../components/templets/stream/Streams';
import { useParams } from 'react-router-dom';


export const StreamPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);
    const { stream } = useParams()
    useEffect(() => {
        const getInfo = async ()=> {
          await getStreams(dispatch,state);
        }
        getInfo();
      }, [])

      useEffect(() => {
        if(state.liveStreams){
          setLoading(false);
        }
      },[state,dispatch])
  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        :
        <>
            <Helmet>
              <title>Live stream - Eurocup3</title>
              <meta name="description" content={"Live stream for the races"} />
              <link rel="canonical" href={document.location} />
            </Helmet>
            <Streams url={state.liveStreams[stream]}/>
        </>
        }
    </>
  )
}

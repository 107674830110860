import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { AboutPostsTemplate } from '../components/templets/AboutPostsTemplate/AboutPostsTemplate';
import { getLegals } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';


export const LegalPostsPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);


    useEffect(() => {
      const getInfo = async () => {
        await getLegals(dispatch,state)
      }
      getInfo();
    },[])

    useEffect(() => {
      if(state.pages.legals){
        setLoading(false);
      }
    },[state,dispatch])

  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : 
        <>
            <Helmet>
              <title>Legals</title>
              <meta name="description" content={"Legals"} />
              <link rel="canonical" href={document.location} />
            </Helmet>
            <AboutPostsTemplate
                titlesSideNav={"Legals"}
                arrayAbout={state.pages.legals}
                section={"legals"}
            />
        </>
        }
    </>
  )
}

const screen = {
    small: 0,
    medium: 600,
    interMedium: 820,
    large: 960
  };
  
  function resizeHandler() {
    // get window width
    const iw = window.innerWidth;
    // determine named size
    let aSize = null;
    for (let s in screen) {
      if (iw >= screen[s]) aSize = s;
    }
    return(aSize)
  }

    export default resizeHandler;
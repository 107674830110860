import React, { useEffect } from "react";
import "./homeTemplate.css";

import text from "../../../assets/data/staticText.json";
import resizeHandler from "../../../utils/sizeScreen";
import { Logo } from "../../atoms/logo/Logo";
import { Text } from "../../atoms/Text/Text";
import { TimerComing } from "../../molecules/timerComing/TimerComing";
import { NewsSection } from "../../organisms/NewsSection/NewsSection";
import { CircuitSection } from "../../organisms/circuitSection/CircuitSection";
import { RegisterSection } from "../../organisms/registerSection/RegisterSection";
import { useCup3 } from '../../../context/euroCup3/cup3State';
import { getStreamSettings } from '../../../context/euroCup3/cup3Action';

export const HomeTemplate = ({ circuits, rounds, newsArray , drivers, teams ,steam, season}) => {
  //get static text
  const { home } = text;
  const [state, dispatch] = useCup3();

  const [trackTimer, setTrackTimer] = React.useState({});
  const [presentTrack, setPresentTrack] = React.useState(true);
  const [live, setLive] = React.useState({});
  //get size screen
  const [size, setSize] = React.useState("");
  const sizeScreen = () => {
    setSize(resizeHandler());
  };
  useEffect(() => {
    sizeScreen();
  }, []);
  window.addEventListener("resize", sizeScreen);
  useEffect(() => {
    const getInfo = async () => {
      await getStreamSettings(dispatch,state);
    }
    getInfo();
  },[])

  //Any race in progress?
  useEffect(() => {
    setTrackTimer(circuits.find((circuit) => circuit.status === "present"));
  }, []);

  //next coming race
  useEffect(() => {
    if (trackTimer === undefined) {
      setPresentTrack(false);
      setTrackTimer(circuits.find((circuit) => circuit.status === "future"));
    } else if (rounds !== undefined) {
      setLive(rounds.find((circuit) => circuit.status === "present"));
    }
  }, [trackTimer]);
  return (
    <div>
    {
      steam.live ?
      (<div className="iframe-home">
        <iframe src={steam["live-youtube"]} uk-video="automute: true"></iframe>
      </div>)
      :
      <div className="homeTemplateSection1">
        <div className="homeTemplateSection1Mask">
          <div className="homeTemplateSection1Content">
            <Logo color="white" />
            {size == "small" ? null : (
              <div className="hiddenTitle">
                <Text color="white" seo={"h1"}>
                  Eurocup 3
                </Text>
              </div>
            )}
            {size == "small" ? null : (
              <Text color="white">{home.initialText}</Text>
            )}
          </div>
          {trackTimer && (
            <TimerComing
              tiempo={trackTimer.initialDate}
              country={trackTimer.country}
              smallFlag={trackTimer.smallFlag}
              nameTrack={trackTimer.name}
              topSubtitle={
                presentTrack ? home.timer.topSubtitleP : home.timer.topSubtitle
              }
              countingTime={home.timer.CountingTime}
              present={presentTrack}
              onlyLiveTrack={state.livetrack}
            />
          )}
        </div>
      </div>
    }
      <div className="homeTemplateSection2">
        <Text size="h2" seo={"h2"}>
          {home.titleS2}
        </Text>
        <div className="homeTemplateSection2News">
          <NewsSection news={newsArray.slice(0, 5)} />
        </div>
      </div>
      <div className="homeTemplateSection3">
        <Text size="h2" seo={"h2"}>
          {home.titleS3}
        </Text>
    {trackTimer &&
       <CircuitSection
          circuits={circuits.sort((a, b) => a.round - b.round)}
          list="true"
          backList={false}
          roundCircuit={
            trackTimer.round? 
            Number(trackTimer.round) 
            :
            1
          }
          rounds={rounds}
          drivers={drivers}
          teams={teams}
          season={season}
        />
      }
      </div>
      {/* <RegisterSection
        title={home.titleS4}
        subTitle={home.subTitleS4}
        textButton={home.buttonS4}
      /> */}
    </div>
  );
};

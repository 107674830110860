import React from 'react'
import './posteDate.css'
import { Text } from '../Text/Text'

export const PosteDate = ({date}) => {
  return (
    <div className='posteDate'>
      <Text size='heroText' color='white'>{date}</Text>
    </div>
  )
}

PosteDate.defaultProps = {
  date: '12 nov'
}

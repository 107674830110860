import React from 'react'
import "./pilotsStanding.css"
import { Text } from '../Text/Text'

export const PilotsStanding = ({number,namePilot,teamPilot,scorePilot,teamColor}) => {
  return (
    <div className='pilotsStanding'>
      <Text size={"c1"}>{number}</Text>
      <div className='namePilotsStanding' style={{borderLeft:`5px solid ${teamColor}`}}>
        <Text size={"b-large"}>{namePilot}</Text>
        <Text size={"b-small"}>{teamPilot}</Text>
      </div>
      <Text size={"c1"}>{scorePilot} Points</Text>
    </div>
  )
}

PilotsStanding.defaultProps = {
  number: '1',
  namePilot: 'namePilot',
  teamPilot: 'teamPilot',
  scorePilot: 'scorePilot',
  teamColor: '#618CFC'
}
import React from 'react'
import './formCRM.css'
import text from '../../../assets/data/staticText.json' 

export const FormCRM = () => {
	const {contact} = text
  function checkMandatory531476000001358033() {
		var mndFileds = new Array('First Name','Last Name');
		var fldLangVal = new Array('Name','Email');
		for(const i=0;i<mndFileds.length;i++) {
		  var fieldObj=document.forms['WebToContacts531476000001358033'][mndFileds[i]];
		  if(fieldObj) {
			if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
			 if(fieldObj.type =='file')
				{ 
				 alert('Seleccione un archivo para cargar.'); 
				 fieldObj.focus(); 
				 return false;
				} 
			alert(fldLangVal[i] +' no puede estar vacío.'); 
   	   	  	  fieldObj.focus();
   	   	  	  return false;
			}  else if(fieldObj.nodeName=='SELECT') {
  	   	   	 if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
				alert(fldLangVal[i] +' no puede ser nulo.'); 
				fieldObj.focus();
				return false;
			   }
			} else if(fieldObj.type =='checkbox'){
 	 	 	 if(fieldObj.checked == false){
				alert('Please accept  '+fldLangVal[i]);
				fieldObj.focus();
				return false;
			   } 
			 } 
			 try {
			     if(fieldObj.name == 'Last Name') {
				const name = fieldObj.value;
 	 	 	    }
			} catch (e) {}
		    }
		}document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
	}

function tooltipShow531476000001358033(el){
	var tooltip = el.nextElementSibling;
	var tooltipDisplay = tooltip.style.display;
	if(tooltipDisplay == 'none'){
		var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
		for(const i=0; i<allTooltip.length; i++){
			allTooltip[i].style.display='none';
		}
		tooltip.style.display = 'block';
	}else{
		tooltip.style.display='none';
	}
}
  return (
    <>
      <div id='crmWebToEntityForm' class='zcwf_lblRight crmWebToEntityForm' style={{color: "black", maxWidth: 600}}>

          <form action='https://crm.zoho.eu/crm/WebToContactForm' name="WebToContacts531476000001358033"method='POST' onSubmit='javascript:document.charset="UTF-8"; return checkMandatory531476000001358033()' accept-charset='UTF-8'>
        <input type='text' style={{display:"none"}} name='xnQsjsdp' value='d1b6925747280f96030cbc7029b3f8ad90aef21da547f4ff8693f81618ee4cdd'/>
        <input type='hidden' name='zc_gad' id='zc_gad' value=''/> 
        <input type='text' style={{display:"none"}} name='xmIwtLD' value='86838d61389ed8b115d03295cb092ab01f6b0a3351423c4765751b1ac39fda70'/>
        <input type='text'  style={{display:"none"}} name='actionType' value='Q29udGFjdHM='/>
        <input type='text' style={{display:"none"}} name='returnURL' value='https&#x3a;&#x2f;&#x2f;eurocup3.org' /> 
        <div class="zcwf_title" style={{color: "white"}}>{contact.title}</div>
<div class="zcwf_text" style={{color: "white"}}>{contact.description}<span style={{color: "black"}}>{contact.email}</span></div>
<div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab' style={{fontSize:12, fontFamily: "Arial"}}><label for='CONTACTCF1'>Marca Asignada</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' id='CONTACTCF1' name='CONTACTCF1' multiple >
			<option value='FOC'>FOC</option>
			<option value='H4R'>H4R</option>
		<option value='PHS'>PHS</option>
			<option selected value='TKA'>TKA</option>
		</select><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab' style={{fontSize:12, fontFamily: "Arial"}}><label for='CONTACTCF11'>Sector Actividad</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' id='CONTACTCF11' name='CONTACTCF11'  >
			<option value='-None-'>-None-</option>
			<option value='Banking'>Banking</option>
			<option value='Beauty'>Beauty</option>
			<option value='Constrution'>Constrution</option>
			<option value='Fashion'>Fashion</option>
			<option value='Food'>Food</option>
			<option value='Horeca'>Horeca</option>
		<option selected value='Motorsport'>Motorsport</option>
			<option value='Optics'>Optics</option>
			<option value='Other'>Other</option>
			<option value='Pharma'>Pharma</option>
			<option value='Press'>Press</option>
			<option value='Security'>Security</option>
			<option value='Shopping&#x20;Center'>Shopping Center</option>
		</select><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab' style={{fontSize:12, fontFamily: "Arial"}}><label for='CONTACTCF5'>Sub Sector Actividad</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' id='CONTACTCF5' name='CONTACTCF5' multiple >
			<option value='Motorbikes'>Motorbikes</option>
			<option value='F4'>F4</option>
		<option selected value='F3'>F3</option>
			<option value='KM'>KM</option>
			<option value='KM&#x20;Press'>KM Press</option>
			<option value='Retail'>Retail</option>
			<option value='Sports'>Sports</option>
			<option value='Wholesale'>Wholesale</option>
			<option value='Brand'>Brand</option>
			<option value='Dealer'>Dealer</option>
			<option value='Partner'>Partner</option>
			<option value='No&#x20;specific'>No specific</option>
		</select><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_fld'><input placeholder="Your Name" type='text' id='First_Name' name='First Name' maxlength='40'/><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_fld'><input placeholder="Your Email" type='text' id='Last_Name' name='Last Name' maxlength='80'/><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab' style={{fontSize:12, fontFamily: "Arial"}}><label for='CONTACTCF6'>Language</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' id='CONTACTCF6' name='CONTACTCF6'  >
			<option value='-None-'>-None-</option>
		<option selected value='English'>English</option>
			<option value='Spanish'>Spanish</option>
		</select><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_fld'><textarea placeholder="Message" id='Description' name='Description'></textarea>
<div class='zcwf_col_help'></div></div></div>

<div class='zcwf_row'><div class='zcwf_col_lab'></div><div class='zcwf_col_fld buttonContainer'><input type='submit' id='formsubmit' class='formsubmit zcwf_button' value='Send' title='Send'/><input type='reset' class='zcwf_button' name='reset' value='Restore' title='Restore'/></div>
</div>
<script id='wf_anal' src='https://crm.zohopublic.eu/crm/WebFormAnalyticsServeServlet?rid=86838d61389ed8b115d03295cb092ab01f6b0a3351423c4765751b1ac39fda70gidd1b6925747280f96030cbc7029b3f8ad90aef21da547f4ff8693f81618ee4cddgidea93f4322d46ee698fe0c65bddf10eabbc5de3875e0e613a51d77e7d4100e249gidc4408f450319003e0c12e54df63a8632&tw=cbfb750ab7d38d47a14b773ee2feb5d02289cbd83eea2c768228fc69ce2034ab'></script>
</form>
</div>
 </>
  )
}

import React from 'react'
import './faqCard.css'
import { PropTypes } from 'prop-types'
import { Text } from '../../../atoms/Text/Text'
import { useNavigate } from 'react-router-dom'
import { convertToSlug } from '../../../../utils/stringUtils'

export const FaqCard = ({image,title, className}) => {
  const navigate = useNavigate()

  return (
    <div>
      <div className={`faqCard ${className}`} onClick={() =>{ navigate(`/about/${convertToSlug(title)}`) }}>
        <div className='imageFaqCard'>
          <img src={image} alt={title} className="" responsive={true}  />
        </div>

          <div className='titleFaqCard'>
              <Text size='h6'>{title}</Text>
          </div>
      </div>
    </div>
  )
}

FaqCard.defaultProps = {
  image: '',
  title: 'title',
  subtitle: 'subtitle',
  link: '',
  className: '',
}

FaqCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
}
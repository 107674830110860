import React from "react";
import "./footer.css";
import { PartnerIcons } from "../../molecules/parnerIcons/PartnerIcons";
import { SubFooter } from "../../molecules/subFooter/SubFooter";
import { ContentFooter } from "../../molecules/contentFooter/ContentFooter";
import { config } from "../../../utils/configAPI";

export const Footer = ({
  partners,
  ListContent1,
  ListContent2,
  newsletter,
  socialIcons,
  bottomFoot,
}) => {
  const conf = config("production");
  return (
    <div className='footerContainer'>
      <div className='kayakContainer'>
        <iframe
          className='kayakContainerFrame'
          src={`${conf.URL}/kayak/`}
          width='100%'
          style={{ border: 0, padding: "10px", background: "#FFFFFF" }}
          allowFullScreen=''
          loading='lazy'
        ></iframe>
      </div>
      <PartnerIcons title={"Partners"} arrayIcons={partners} />
      <ContentFooter
        ListContent1={ListContent1}
        ListContent2={ListContent2}
        newsletter={newsletter}
        arrayIcons={socialIcons}
      />
      <SubFooter right={bottomFoot.right} left={bottomFoot.left} />
    </div>
  );
};

Footer.defaultProps = {
  kayak: {},
  partners: {
    title: "Partners",
    array: [
      "static/media/Apple - Original.8c09b1d9ad1326f76999be0e7da698a6.svg",
      "static/media/Github - Original.6017c6f9464c9c5b9fbf62b7ec5c50ba.svg",
    ],
  },
  listFooter1: {
    title: "The Eurocup-3",
    content: [
      { name: "where to watch us", onclick: "/" },
      { name: "Media Kit", onclick: "/" },
      { name: "Contact", onclick: "/" },
    ],
  },
  listFooter2: {
    title: "Legal",
    content: [
      { name: "terms and conditions", onclick: "/" },
      { name: "cookie policy", onclick: "/" },
      { name: "Privacy", onclick: "/" },
    ],
  },
  NewsletterFooter: {
    title: "Newsletter",
    content: "Suscribe to our newsletter to receive updates and news ",
    placeholder: "Enter your email to subscribe",
    textButton: "Register",
  },
  bottomFoot: {
    right: "© 2023 EuroCup3. All rights reserved.",
    left: "Developed By The Kilite",
  },
};

import React from "react";
import PropTypes from "prop-types";
import "./button.css";
import { Text } from "../Text/Text";

export const Buttons = ({ type, size, children, onclick }) => {
  return (
    <button
      onClick={onclick}
      className={`button button-${type} button-${size}`}
    >
      <Text color="white" size={`b-${size}`}>
        {children}
      </Text>
    </button>
  );
};

Buttons.defaultProps = {
  type: "black",
  size: "large",
  children: "button",
  onclick: () => {},
};

Buttons.propTypes = {
  type: PropTypes.oneOf(["black", "danger"]),
  size: PropTypes.oneOf(["large", "medium", "small"]),
  children: PropTypes.string,
  onclick: Function.prototype,
};

import React from "react";
import PropTypes from "prop-types";
import "./icons.css";

import facebookN from "../../../assets/iconsSVG/negative/Facebook - Negative.svg";
import facebookO from "../../../assets/iconsSVG/original/Facebook - Original.svg";

import twitterN from "../../../assets/iconsSVG/negative/Twitter - Negative.svg";
import twitterO from "../../../assets/iconsSVG/original/Twitter - Original.svg";

import instagramN from "../../../assets/iconsSVG/negative/Instagram - Negative.svg";
import instagramO from "../../../assets/iconsSVG/original/Instagram - Original.svg";

import linkedinN from "../../../assets/iconsSVG/negative/LinkedIn - Negative.svg";
import linkedinO from "../../../assets/iconsSVG/original/LinkedIn - Original.svg";

import googleN from "../../../assets/iconsSVG/negative/Google - Negative.svg";
import googleO from "../../../assets/iconsSVG/original/Google - Original.svg";

import youtubeN from "../../../assets/iconsSVG/negative/YouTube - Negative.svg";
import youtubeO from "../../../assets/iconsSVG/original/YouTube - Original.svg";

import appleN from "../../../assets/iconsSVG/negative/Apple - Negative.svg";
import appleO from "../../../assets/iconsSVG/original/Apple - Original.svg";

import snapchatN from "../../../assets/iconsSVG/negative/Snapchat - Negative.svg";
import snapchatO from "../../../assets/iconsSVG/original/Snapchat - Original.svg";

import pinterestN from "../../../assets/iconsSVG/negative/Pinterest - Negative.svg";
import pinterestO from "../../../assets/iconsSVG/original/Pinterest - Original.svg";

import mediumN from "../../../assets/iconsSVG/negative/Medium - Negative.svg";
import mediumO from "../../../assets/iconsSVG/original/Medium - Original.svg";

import githubN from "../../../assets/iconsSVG/negative/Github - Negative.svg";
import githubO from "../../../assets/iconsSVG/original/Github - Original.svg";

import skypeN from "../../../assets/iconsSVG/negative/Skype - Negative.svg";
import skypeO from "../../../assets/iconsSVG/original/Skype - Original.svg";

import figmaN from "../../../assets/iconsSVG/negative/Figma - Negative.svg";
import figmaO from "../../../assets/iconsSVG/original/Figma - Original.svg";

import dribbbleN from "../../../assets/iconsSVG/negative/Dribbble - Negative.svg";
import dribbbleO from "../../../assets/iconsSVG/original/Dribbble - Original.svg";

import redditN from "../../../assets/iconsSVG/negative/Reddit - Negative.svg";
import redditO from "../../../assets/iconsSVG/original/Reddit - Original.svg";

import discordN from "../../../assets/iconsSVG/negative/Discord - Negative.svg";
import discordO from "../../../assets/iconsSVG/original/Discord - Original.svg";

import tiktokN from "../../../assets/iconsSVG/negative/TikTok - Negative.svg";
import tiktokO from "../../../assets/iconsSVG/original/TikTok - Original.svg";

import tumblrN from "../../../assets/iconsSVG/negative/Tumblr - Negative.svg";
import tumblrO from "../../../assets/iconsSVG/original/Tumblr - Original.svg";

import telegramN from "../../../assets/iconsSVG/negative/Telegram - Negative.svg";
import telegramO from "../../../assets/iconsSVG/original/Telegram - Original.svg";

import ClubhouseN from "../../../assets/iconsSVG/negative/Clubhouse - Negative.svg";
import ClubhouseO from "../../../assets/iconsSVG/original/Clubhouse - Original.svg";

import signalN from "../../../assets/iconsSVG/negative/Signal - Negative.svg";
import signalO from "../../../assets/iconsSVG/original/Signal - Original.svg";

import vkN from "../../../assets/iconsSVG/negative/VK - Negative.svg";
import vkO from "../../../assets/iconsSVG/original/VK - Original.svg";

import spotifyN from "../../../assets/iconsSVG/negative/Spotify - Negative.svg";
import spotifyO from "../../../assets/iconsSVG/original/Spotify - Original.svg";

import twichN from "../../../assets/iconsSVG/negative/Twitch - Negative.svg";
import twichO from "../../../assets/iconsSVG/original/Twitch - Original.svg";

import serchN from "../../../assets/iconsSVG/negative/Search - Negative.svg";
import serchO from "../../../assets/iconsSVG/original/Search - Original.svg";

import accountN from "../../../assets/iconsSVG/negative/Account - Negative.svg";
import accountO from "../../../assets/iconsSVG/original/Account - Original.svg";

import menuN from "../../../assets/iconsSVG/negative/Menu - Negative.svg";
import menuO from "../../../assets/iconsSVG/original/Menu - Original.svg";

import dropdownN from "../../../assets/iconsSVG/negative/Dropdown - Negative.svg";
import dropdownO from "../../../assets/iconsSVG/original/Dropdown - Original.svg";

import playN from "../../../assets/iconsSVG/negative/Play - Negative.svg";
import playO from "../../../assets/iconsSVG/original/Play - Original.svg";

import separator from "../../../assets/iconsSVG/original/separator.svg";

export const Icons = ({ icon, negative, size, button, onclick }) => {
  const svgIcons = {
    facebook: negative ? facebookN : facebookO,
    twitter: negative ? twitterN : twitterO,
    instagram: negative ? instagramN : instagramO,
    linkedin: negative ? linkedinN : linkedinO,
    google: negative ? googleN : googleO,
    youtube: negative ? youtubeN : youtubeO,
    apple: negative ? appleN : appleO,
    snapchat: negative ? snapchatN : snapchatO,
    pinterest: negative ? pinterestN : pinterestO,
    medium: negative ? mediumN : mediumO,
    github: negative ? githubN : githubO,
    skype: negative ? skypeN : skypeO,
    figma: negative ? figmaN : figmaO,
    dribbble: negative ? dribbbleN : dribbbleO,
    reddit: negative ? redditN : redditO,
    discord: negative ? discordN : discordO,
    tiktok: negative ? tiktokN : tiktokO,
    tumblr: negative ? tumblrN : tumblrO,
    telegram: negative ? telegramN : telegramO,
    Clubhouse: negative ? ClubhouseN : ClubhouseO,
    signal: negative ? signalN : signalO,
    vk: negative ? vkN : vkO,
    spotify: negative ? spotifyN : spotifyO,
    twich: negative ? twichN : twichO,
    search: negative ? serchN : serchO,
    account: negative ? accountN : accountO,
    menu: negative ? menuN : menuO,
    dropdown: negative ? dropdownN : dropdownO,
    play: negative ? playN : playO,
    separator: separator,
  };
  return (
      <img
        alt="icon"
        onClick={onclick}
        src={svgIcons[icon]}
        className={`icons--${size} ${button ? "buttonIcon" : ""}`}
      />
  );
};
Icons.defaultProps = {
  icon: "apple",
  negative: false,
  size: "medium",
  button: false,
  onclick: () => {},
};

Icons.propTypes = {
  icon: PropTypes.string,
  negative: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large", "big", "huge"]),
  button: PropTypes.bool,
  onclick: PropTypes.func,
};

import React from 'react'
import './standingCell.css'

export const StandingCell = ({Q1,R1,FL1,Q2,R2,FL2,R3,FL3,extraRace,type="driver"}) => {
  const race1 = R1
  if(type==="driver"){
    return (
      <div className='standingCell'>
      <div className={Q1===0?"standing0":'standingN1'}>{Q1}</div>
      <div className={R1===0?"standing0":'standingN2'}>{R1}{FL1?<span className='red'>+1</span>:""}</div>
      <div className={Q2===0?"standing0":'standingN1'}>{Q2}</div>
      <div className={R2===0?"standing0":'standingN2'}>{R2}{FL2?<span className='red'>+1</span>:""}</div>
      {extraRace ? <div className={R3===0?"standing0":'standingN2'}>{R3}{FL3?<span className='red'>+1</span>:""}</div> : ''}
    </div>
  )}
  else if(type==="team"){
    return (
      <div className='standingCell standingCellTeam'>
        {/* <div className={R1===0?"standing0":'standingN2'}>{R1}{FL1?<span className='red'>+1</span>:""}</div>
        <div className={R2===0?"standing0":'standingN2'}>{R2}{FL2?<span className='red'>+1</span>:""}</div> */}
         <div className={R1===0?"standing0":'standingN2'}>{R1}</div>
        <div className={R2===0?"standing0":'standingN2'}>{R2}</div>
          {extraRace ? <div className={R3===0?"standing0":'standingN2'}>{R3}</div> : ''}
      </div>
    )
  }
}

StandingCell.defaultProps = {
  Q1: 0,
  R1: 0,
  FL1: 0,
  Q2: 0,
  R2: 0,
  FL2: 0,
  R3: 0,
  FL3: 0,
  type: "driver"
}
import React from 'react'
import "./subFooter.css"
import { PropTypes } from 'prop-types'
import { Text } from '../../atoms/Text/Text'

export const SubFooter = ({right,left}) => {
  return (
    <div className='subFooter'>
        <Text size='p' color='white'>{left}</Text>
        <Text size='p' color='white'>{right}</Text>
    </div>
  )
}

SubFooter.defaultProps = {
  right: 'Copyright 2023 FIA association',
  left: 'Developed by TheKilite Company'
}

SubFooter.propTypes = {
  right: PropTypes.string,
  left: PropTypes.string
}
import React from 'react'
import "./aboutTemplate.css"
import text from '../../../assets/data/staticText.json' 
import { Text } from '../../atoms/Text/Text'
import { Accordions } from '../../molecules/accordion/Accordion'
import { FaqSection } from '../../organisms/faqSection/FaqSection'

export const AboutTemplate = ({arrayFaqs,arrayAcc}) => {
  const {about} = text
  return (
    <div>
        <div className='aboutSection1'>
          <Text size='h2' seo={"h1"}>{about.titleS1}</Text>
          <FaqSection arrayInfoFaqs={arrayFaqs}/>
        </div>
        <div className='aboutSection2'>
            <Text size='h2' seo={"h2"}>{about.titleS2}</Text>
            <Accordions arrayAcc={arrayAcc} />
        </div>
    </div>
  )
}

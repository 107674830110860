import React from "react";
import "./roundInfo.css";
import text from './../../../assets/data/staticText.json' 
import { Grid } from "uikit-react";
import { Characteristic } from "../../atoms/characteristic/Characteristic";
import { PilotsStanding } from "../../atoms/pilotsStanding/PilotsStanding";
import { Text } from "../../atoms/Text/Text";
import { InfoRoundsDetails } from "../../molecules/infoRoundsDetails/InfoRoundsDetails";

export const RoundInfo = ({numRound,podcast,weather,date,bestsLaps,standing,latestRound,extraRace}) => {
  const {round} = text
  return (
    <div className='roundInfo'>
        <Grid className='uk-child-width-1-3@m alignRoundInfo'>
          <InfoRoundsDetails 
            title={round.titleHeader1}
            subtitle={round.subtitleHeader1}
            date={date}
            podcast={podcast}
            weather={weather}
            numRound={numRound}
            latestRound={latestRound}
          />
          <div className='roundInfoS2'>
            <Text size={"h6"} color={"white"}>
            {round.titleHeader2}
            </Text>
            {bestsLaps["first-race"] &&
              <Characteristic
                name={"Race 1"}
                value={`${bestsLaps["first-race"].time}`}
                value2={`${ (bestsLaps["first-race"].time !== "0:00.000" && bestsLaps["first-race"]["driver"]) ? bestsLaps["first-race"].driver.driverName : "---"} `}
                colorLine={"white"}
                padding={"16px"}
              />
            }
            {bestsLaps["second-race"].time &&
              <Characteristic
                name={"Race 2"}
                value={`${bestsLaps["second-race"].time}`}
                value2={`${(bestsLaps["second-race"].time !== "0:00.000" && bestsLaps["second-race"]["driver"]) ? bestsLaps["second-race"].driver?.driverName: "---"}`}
                colorLine={"white"}
                padding={"16px"}
              />
            }
            {extraRace && bestsLaps["third-race"].time && 
              <Characteristic
                name={"Race 3"}
                value={`${bestsLaps["third-race"].time}`}
                value2={`${(bestsLaps["third-race"].time !== "0:00.000" && bestsLaps["third-race"]["driver"]) ? bestsLaps["third-race"].driver?.driverName: "---"}`}
                colorLine={"white"}
                padding={"16px"}
              />
            }
            {bestsLaps["qualification-race-1"].time &&
              <Characteristic
                name={"Qualy 1"}
                value={bestsLaps["qualification-race-1"].time}
                value2={`${(bestsLaps["qualification-race-1"].time !== "0:00.000" && bestsLaps["qualification-race-1"]["driver"]) ? bestsLaps["qualification-race-1"].driver?.driverName: ""}`}
                colorLine={"white"}
                padding={"16px"}
              />
            }
            {bestsLaps["qualification-race-2"].time &&
              <Characteristic
                name={"Qualy 2"}
                value={bestsLaps["qualification-race-2"].time}
                value2={`${bestsLaps["qualification-race-2"].time !== "0:00.000" ? bestsLaps["qualification-race-2"].driver?.driverName: ""}`}
                colorLine={"white"}
                padding={"16px"}
              />
            }
          </div>
          <div className='roundInfoS3'>
            <Text size={"h6"} color={"white"}>
            {round.titleHeader3}
            </Text>
            {standing &&
              standing.map((pilot,index)=>{
                if (pilot.name != undefined) { 
                  return (
                      <PilotsStanding 
                        key={index}
                        number={pilot.numDriver}
                        namePilot={pilot.name}
                        teamPilot={pilot.team}
                        scorePilot={pilot.points}
                        teamColor={pilot.teamColor}
                      />
                  )
                }else {
                  return(
                    console.log("no pilot defined")
                  )
                }
              })
            }
          </div>
        </Grid>
    </div>
  );
};


RoundInfo.defaultProps = {
  numRound: "1",
  podcast: "",
  weather: "day-sunny",
  date: "12 nov",
  bestsLaps:{
      "Free practice": "1:44.484",
      "Qualify session": "1:44.484",
      "Sprint Race": "1:44.484",
      "Feature Race": "1:44.484",
    },
  
  standing: [
    {
      number: "1",
      namePilot: "namePilot",
      teamPilot: "teamPilot",
      scorePilot: "scorePilot",
      teamColor: "#618CFC",
    },
    {
      number: "2",
      namePilot: "namePilot",
      teamPilot: "teamPilot",
      scorePilot: "scorePilot",
      teamColor: "#618CFC",
    },
    {
      number: "3",
      namePilot: "namePilot",
      teamPilot: "teamPilot",
      scorePilot: "scorePilot",
      teamColor: "#618CFC",
    }
  ]
};
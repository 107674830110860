import React from 'react'
import './firstBar.css'
import { Icons } from '../../atoms/icons/Icons'
import { SocialIcons } from '../../molecules/socialIcons/SocialIcons'
import { useNavigate } from 'react-router-dom'
import { config } from '../../../utils/configAPI'


export const FirstBar = ({arrayIcons,present}) => {
  const navigate = useNavigate()
  const intranet = () => {
    window.location.href = config("production").URL+ "/intranet/"
  }
  return (
    <div className='firstBar'>
      {
      present && 
        <div className='LiveTrack' >
          <div style={{height:"min-content"}} onClick={()=>{navigate("streams/live-track")}} >LIVE TIMING</div>
          <div style={{height:"min-content"}} onClick={()=>{navigate("streams/live-youtube")}} >STREAMING</div>
        </div>
      }
      <Icons icon='search' negative={true} size='small'/>
      <Icons icon="separator" size="small"/>
      <SocialIcons arrayIcons={arrayIcons} size='small'/>
      <div className='userFirstBar' >
        <Icons icon="account" negative={true} size="small" button={true} onclick={intranet}/>
        <Icons icon="dropdown" negative={true} size="small" button={true} onclick={intranet}/>
      </div>
    </div>
  )
}

FirstBar.defaultProps = {
  present:true,
}
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { convertToSlug } from '../../../utils/stringUtils'
import { SideNavMenu } from '../../molecules/navigation/SideNavMenu/SideNavMenu'
import "./aboutPostsTemplate.css"


export const AboutPostsTemplate = ({titlesSideNav,arrayAbout,section}) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const [post, setPost] = React.useState([])
  const titlesNav = arrayAbout.map((item) => { return {name:item.title.rendered , onclick: () =>{ navigate(`/${section}/${convertToSlug(item.title.rendered)}`) }}})
  React.useEffect(() => {
    const postFilter = arrayAbout.find((item,index) => {
      if (id == undefined &  index === 0) return item
      return convertToSlug(item.title.rendered) === (id)
    })
    setPost(postFilter.content.rendered)
  }, [id,arrayAbout])
  return (
    <div className='uk-container'>
      <div className='aboutPostsTemplate uk-grid' >
        <div className='uk-width-1-4@s uk-width-1-4@m navAboutPosts'>
          <SideNavMenu title={titlesSideNav} arrayLinks={titlesNav}/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post}} className='uk-width-expand@m'>
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom';
import './index.css';
import App from './App';
import { hydrate, render } from "react-dom";

const root = document.getElementById('root');
createRoot(root).render(<App />);
// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }
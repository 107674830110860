import React from 'react'
import './accordion.css'
import { AccordionItem, Accordion } from 'uikit-react'
import { PropTypes } from 'prop-types'

export const Accordions = ({arrayAcc}) => {
  return (
    <Accordion>
        {
            arrayAcc.map((item,index) => {
                return (
                    <AccordionItem className="accordionItem" key={index} title={item.questionfaq}>{item.responsfaq}</AccordionItem>
                )})
        }
    </Accordion>
  )
}

Accordions.defaultProps = {
  arrayAcc: [{title: 'title', content: 'content'},{title: 'title', content: 'content'}]
}

Accordions.propTypes = {
  arrayAcc: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string
  }))
}
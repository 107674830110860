import React from "react";
import "./inputText.css";
import { Buttons } from "../button/Button";
import { PropTypes } from "prop-types";
import { fetchNewsLatter } from "../../../utils/createNewNewsLatter";

export const InputText = ({
  placeholder,
  colum,
  colorButton,
  onclick,
  textButton,
}) => {

  const [inputEmail, setInputEmail] = React.useState("");

  const getToken = async () => {
      setInputEmail("");
      fetchNewsLatter(inputEmail);
      onclick();
  };

  const onInputChange = (e) => {
    setInputEmail(e.target.value);
  };

  return (
    <div className={`blockInputText ${colum ? "inColumn" : "inLine"}`}>
      <input
        value={inputEmail}
        className="inputText"
        type="text"
        placeholder={placeholder}
        onChange={onInputChange}
      />
      <Buttons type={colorButton} text="Button" onclick={getToken}>
        {textButton}
      </Buttons>
    </div>
  );
};

InputText.defaultProps = {
  placeholder: "placeholder",
  colum: true,
  colorButton: "danger",
  textButton: "Button",
  onclick: () => {},
};

InputText.propTypes = {
  placeholder: PropTypes.string,
  colum: PropTypes.bool,
  colorButton: PropTypes.oneOf(["black", "danger"]),
  textButton: PropTypes.string,
  onclick: Function.prototype,
};

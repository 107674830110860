import React from 'react'
import './infoCircuitRound.css'
import flagNames from "../../../assets/data/flags.json";
import { Text } from '../../atoms/Text/Text'
import rectangle from '../../../assets/image/Rectangle.png'
import { PropTypes } from 'prop-types'
import { PosteDate } from '../../atoms/posteDate/PosteDate'
import { FlagKit } from '../../atoms/flagKit/FlagKit'

export const InfoCircuitRound = ({round,country,flag,name,date}) => {
  return (
    <div className='InfoCircuitRound'>
      <div className='InfoCircuitRoundContainer'>
        <div className='round'>{round}</div>
        <div className='separator'><img src={rectangle} alt='/'/></div>
        <div className='info'>
            {country !== "" &&
            <div className='country'>
                <Text size='h4' color='white'>
                  {flag?<img src={flag.url} alt={country} className="flagLargeTrack" />:<FlagKit size='large' country={country}/>}
                  {flagNames[country]}
                </Text>
            </div>
            }
            <Text size='heroText caps' seo={"h2"} color='white'>{name}</Text>
        </div>
      </div>
      <PosteDate date={date}/>
    </div>
  )
}

InfoCircuitRound.defaultProps = {
  round: 1,
  country: "",
  name: 'barcelona',
  date: '12 nov'
}

InfoCircuitRound.propTypes = {
  round: PropTypes.number,
  country: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string
}
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { BlogTemplate } from '../components/templets/blogTemplate/BlogTemplate';
import { getBlogs, getTags } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import iconVB from "../assets/logos/eurocup3VB.png";


export const BlogsPage = () => {
  const [state, dispatch] = useCup3();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const getInfo = async () => {
      await getBlogs(dispatch,state);
      await getTags(dispatch,state);
      setLoading( false);
    }
    getInfo();
  },[])

  useEffect(() => {
    if(state.pages.news & state.pages.blogs){
      setLoading(false);
    }
  },[state,dispatch])



  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : <>
            <Helmet>
              <title>Blog - Eurocup 3</title>
              <meta name="description" content={'Blog articles of the Eurocup 3'}/>
              <link rel="canonical" href={document.location} />
              <meta name="robots" content="index, follow"/>
              <meta property="og:title" content={'Blog - Eurocup 3'}/>
              <meta property="og:type" content={"website"} />
              <meta property="og:image" content={iconVB} />
              <meta property="og:url" content={document.location} />
              <meta property="og:site_name" content="Blog - Eurocup 3 Championship" />
              <meta property="og:locale" content="en_GB" />
              <meta property="og:locale:alternate" content="en_US" />
            </Helmet>
            <BlogTemplate
                blog={state.pages.blogs}
                arrayTags={state.pages?.news?.tags}
            />
        </>
        }
    </>
  )
}

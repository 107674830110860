import React from 'react'
import "./driverInfo.css"
import { Text } from '../../atoms/Text/Text'
import { Characteristic } from '../../atoms/characteristic/Characteristic'
import { StatsEuroCup3 } from '../../atoms/statsEuroCup3/StatsEuroCup3'
import { SocialIcons } from '../../molecules/socialIcons/SocialIcons'
import { FlagKit } from '../../atoms/flagKit/FlagKit'

export const DriverInfo = ({image,team,name,country,socialIcons,number, char1,char2,char3, stand1,stand2,status}) => {

  return (
    <div className='infoDriverContainer'>
      <div className='infoDriverTop'>
        <div className='infoDriverTopText'>
          <Text size={"h6"} color="red">{team}</Text>
          <div className='infoDriverTopTextName'>
            <div className='flag-wrap'>
              <FlagKit country={country}/>
            </div>
            <Text size={"h1"} seo={"h1"} color="white">{name}</Text>
          </div>
        </div>
        <SocialIcons arrayIcons={socialIcons}/>
      </div>
      <div className='uk-container width-80'>
        <div className='infoDriverContent uk-grid-match uk-child-width-expand@s uk-text-center uk-grid'>
          <div className="infoDriverImage" >{number > 0 && <div className='driverNum'>{number}</div> }  <img src={image} alt={"pilot"}/></div>
          <div className='infoDriverText'>
            <div className='infoDriverText1'>
              <Characteristic name={char1.name} value={char1.value?char1.value:"-"} T1={"p-large"}  T2={"p-large"}/>
              { char2.value &&
                <div className='bioDriver'>
                  <Characteristic name={char2.name} value={""} T1={"p-large"}  T2={"p-large"}/>
                  <Text size={"p-large"} color="white">{char2.value}</Text>
                </div>
              }
              {/* <Characteristic name={char3.name} value={char3.value?`${char3.value} KG`:"-"} T1={"p-large"}  T2={"p-large"}/> */}
            </div>
            <div className='standingPoints'>
              <StatsEuroCup3 year={2024}/>
              <Characteristic name={stand1.name} value={stand1.value} T1={"p-large"}  T2={"trackName"}/>
              <Characteristic name={stand2.name} value={stand2.value} T1={"p-large"}  T2={"trackName"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DriverInfo.defaultProps = {
  image: "/image/default/pilot_empty.png",
  team: "Team",
  name: "Nombre",
  country: "IT",
  socialIcons: [1,2,3,4],
  number: 17,
  char1: {name:"DOB", value:"12/12/1999"},
  char2: {name:"height", value:"181 cms"},
  char3: {name:"weight", value:"66 kg"},
  char4: {name:"nationality", value:" spanish"},
  stand1: {name:"standing", value:"12th"},
  stand2: {name:"points", value:"245"},
}

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { StandingTemplate } from "../components/templets/standingTemplate/StandingTemplate";
import { StandingTemplatePatch } from "../components/templets/standingTemplate/StandingTemplatePatch";

import {
  getDrivers,
  getRounds,
  getTeams,
} from "../context/euroCup3/cup3Action";
import { useCup3 } from "../context/euroCup3/cup3State";

export const StandingPage = () => {
  const [state, dispatch] = useCup3();
  const [loading, setLoading] = React.useState(true);

  const [year, setYear] = React.useState(429);

  useEffect(() => {
    const getInfo = async () => {
      await getRounds(dispatch, state, year);
      await getDrivers(dispatch, state, year);
      await getTeams(dispatch, state, year);
    };
    getInfo();
  }, [year]);

  useEffect(() => {
    if (
      state.pages.rounds?.[year] &&
      state.pages.drivers?.[year] &&
      state.pages.teams?.[year]
    ) {
      setLoading(false);
    }
  }, [state, dispatch]);

  return (
    <>
      {loading ? (
        <div className='loading-spinner'>
          <span uk-spinner='ratio: 4.5'></span>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Standing</title>
            <meta name='description' content={"Total Standings"} />
            <link rel='canonical' href={document.location} />
          </Helmet>

          <StandingTemplate
            rounds={state.pages.rounds[year]}
            drivers={state.pages.drivers[year]}
            teams={state.pages.teams[year]}
            year={year}
            setYear={setYear}
            seasons={state.seasons}
            season={state.season}
          />
        </>
      )}
    </>
  );
};

import axios from "axios";

const loginData = {
  username: "reactrobot",
  password: "uJ6Cl5Lce4&ZYWw3cKUzy4ry",
};

export const fetchNewsLatter = async (inputEmail) => {
  try {
    const resultToken = await axios.post(
      "https://01h.01d.myftpupload.com/wp-json/jwt-auth/v1/token",
      loginData
    );
    await axios.post(
      "https://01h.01d.myftpupload.com/wp-json/newsletter/v2/subscriptions?client_key=2a0ccbe7ca8b1eb01b53c6a06424200f7904244c&client_secret=427b039a1a843daecfb835bb2045695430a9c060",
      { email: inputEmail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${resultToken.data.data.token}`,
        },
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

import React from 'react'
//import PropTypes from 'prop-types';
import "./text.css"

export const Text = ({children, size, color,seo}) => {
  switch (seo) {
    case "h1":
      return <h1 className={`${size} ${color}`}>{children}</h1>
    case "h2":
      return <h2 className={`${size} ${color}`}>{children}</h2>
    case "h3":
      return <h3 className={`${size} ${color}`}>{children}</h3>
    case "h4":
      return <h4 className={`${size} ${color}`}>{children}</h4>
    case "h5":
      return <h5 className={`${size} ${color}`}>{children}</h5>
    case "h6":
      return <h6 className={`${size} ${color}`}>{children}</h6>
    case "p":
      return <p className={`${size} ${color}`}>{children}</p>
    case "span":
      return <span className={`${size} ${color}`}>{children}</span>
    case "div":
      return <div className={`${size} ${color}`}>{children}</div>
    default:
      return <div className={`${size} ${color}`}>{children}</div>
  }
}

Text.defaultProps = {
  children: "---",
  size: "p",
  color: "black",
  seo: "div"
}

// Text.propTypes = {
//   children: PropTypes.string,
//   size: PropTypes.string,
//   color: PropTypes.oneOf(['black', 'white',"red"]),
//   seo: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div']),
// }
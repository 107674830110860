import React from 'react'
import { abbreviateName } from '../../../utils/stringUtils'
import { Text } from '../../atoms/Text/Text'
import './tableResults.css'

export const TableResults = ({pilots,results,teams, isCancelled, extraRace}) => {
  //console.log('results',results);
  return (
    <div className='tableResults'>
      <div className='uk-overflow-auto' role="region" aria-labelledby="caption" tabIndex="0">

        {!isCancelled ?
          <table className="uk-table uk-table-hover infoTable">
            <thead>
                <tr>
                    <th>Pos</th>
                    <th>Driver</th>
                    <th>Laps</th>
                    <th>Fastest Lap</th>
                    <th>GAP</th>
                </tr>
            </thead>
            <tbody>
              {
                results.map((result, index) => {
                  let pilotStanding = {}
                  let teamStanding = {}
                  pilotStanding = pilots.find(pilot => {
                    return pilot.driverId == result["driver-id"]
                  })
                  if(pilotStanding === undefined){
                    pilotStanding = {
                      driverName: "---",
                      driverNum: "-",
                      teamId: "-",
                    }
                  }else{
                    teamStanding = teams.find(team => {
                      return team.teamId == pilotStanding.teamId
                    })
                  }

                  if(teamStanding === undefined){
                    teamStanding = {
                      teamName: "No team",
                      teamColor: "#fff",
                    }
                  }
                  return (
                    <tr className='lineTable' key={index}>
                      <td>{index+1}</td>
                      <td>
                        <div className='tableRPilotName'>
                          <div className='numPositionTable'>{pilotStanding.driverNum}</div>
                          <div className='tableRPilotNameText' style={{borderLeft:`5px solid ${teamStanding.teamColor}`}}>
                            <Text size={"c1"}>{abbreviateName(pilotStanding.driverName)}</Text>
                            <Text size={"p-small"}>{teamStanding.teamName ? teamStanding.teamName : '---' }</Text>
                          </div>
                        </div>
                      </td>
                      <td>{result.laps? result.laps:"--------"}</td>
                      <td>{result.time? result.time:"--------"}</td>
                      <td>{result.gap? result.gap:"--------"}</td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
        : 
          <div className='cancelledRace'>CANCELLED</div>
        }
      </div>
    </div>
  )
}

TableResults.defaultProps = {
  pilots: [
    {
      driverName: "Lewis Hamilton",
      team: "Mercedes",
      colorTeam: "#00D2BE",
    },
    {
      driverName: "Valtteri Bottas",
      team: "Mercedes",
      colorTeam: "#00D2BE",
    },
    {
      driverName: "Max Verstappen",
      team: "Red Bull Racing",
      colorTeam: "#0600EF",
    }
]
}
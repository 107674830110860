import React from "react";
import "./calendarTemplate.css";
import { VerticalNav } from "../../molecules/navigation/verticalNav/VerticalNav";
import { CircuitSection } from "../../organisms/circuitSection/CircuitSection";
import resizeHandler from "../../../utils/sizeScreen";
import { Text } from "../../atoms/Text/Text";

export const CalendarTemplate = ({
  circuits,
  round,
  drivers,
  teams,
  season,
}) => {
  const rounds = circuits.map((item) => {
    return item.round;
  });

  const [size, setSize] = React.useState("");
  const sizeScreen = () => {
    setSize(resizeHandler());
  };
  React.useEffect(() => {
    sizeScreen();
  }, []);
  window.addEventListener("resize", sizeScreen);
  if (size === "small") {
    return (
      <>
        <div style={{ visibility: "hidden", position: "absolute" }}>
          <Text seo={"h1"} size={"h1"}>
            Euro cup races calendar
          </Text>
        </div>
        <div
          className='uk-slider-container-offset calendar-slider-mobile'
          uk-slider='center: true'
        >
          <div
            className='uk-position-relative uk-visible-toggle uk-light'
            tabIndex='-1'
          >
            <ul className='uk-slider-items uk-child-width-1-2@s uk-grid'>
              {circuits.map((circuit, index) => {
                return (
                  <li key={index}>
                    <div className='uk-panel'>
                      <CircuitSection
                        circuits={circuits.sort((a, b) => a.round - b.round)}
                        nameCircuit={circuit.name}
                        roundCircuit={circuit.round}
                        rounds={round}
                        drivers={drivers}
                        teams={teams}
                        season={season}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <ul className='uk-slider-nav uk-dotnav uk-flex-center uk-margin'></ul>
        </div>
      </>
    );
  }
  return (
    <div className='calendar-page-section'>
      <div style={{ visibility: "hidden", position: "absolute" }}>
        <Text seo={"h1"} size={"h1"}>
          Euro cup races calendar
        </Text>
      </div>
      <div className='verticalNav'>
        <VerticalNav array={rounds.sort((a, b) => a - b)} />
      </div>
      {circuits.map((circuit, index) => {
        return (
          <section key={index} id={`round-${circuit.round}`}>
            <CircuitSection
              circuits={circuits.sort((a, b) => a.round - b.round)}
              nameCircuit={circuit.name}
              roundCircuit={circuit.round}
              rounds={round}
              drivers={drivers}
              teams={teams}
              season={season}
            />
          </section>
        );
      })}
    </div>
  );
};

import React from "react";
import "./blogTemplate.css";

import { NewsSection } from "../../organisms/NewsSection/NewsSection";

import { Pagination } from "../../molecules/pagination/Pagination";
import { FilterSection } from "../../organisms/filterSection/FilterSection";
import resizeHandler from "../../../utils/sizeScreen";
import { Text } from "../../atoms/Text/Text";
import { BlogCard } from "../../molecules/cards/blogCard/BlogCard";

export const BlogTemplate = ({ blog, arrayTags }) => {
  const [page, setPage] = React.useState(1);
  const [tags, setTags] = React.useState(
    arrayTags.map((item) => {
      return item.slug;
    })
  );

  const [blogF, setBlogF] = React.useState(blog);
  const [size, setSize] = React.useState("");
  const sizeScreen = () => {
    setSize(resizeHandler());
  };
  React.useEffect(() => {
    sizeScreen();
  }, []);

  React.useEffect(() => {
    if (tags.length === 0) {
      setTags(
        arrayTags.map((item) => {
          return item.slug;
        })
      );
    }
    const blogFiltrate = blog.blogs.filter((item) => {
      const isCategory = item.category.map((element) => {
        if (tags.includes(element.slug)) {
          return true;
        } else {
          return false;
        }
      });
      if (isCategory.includes(true)) {
        return true;
      }
    });
    setBlogF(blogFiltrate);
  }, [tags, blog]);

  window.addEventListener("resize", sizeScreen);

  const callBackPage = (a) => {
    setPage(a);
  };

  const arregloDeArreglos = []; // Aquí almacenamos los nuevos arreglos
  const LONGITUD_PEDAZOS = 10;

  for (let i = 0; i < blogF.length; i += LONGITUD_PEDAZOS) {
    let pedazo = blogF.slice(i, i + LONGITUD_PEDAZOS);
    arregloDeArreglos.push(pedazo);
  }

  return (
    <div>
      
        <div className="contentBlogsTemplate">
          <Text size="h1" seo={"h1"}>
            Blog
          </Text>

          {blog.blogs.length > 10 && (
            <FilterSection
              arrayTags={arrayTags.map((item) => {
                return item.slug;
              })}
              callbackTags={setTags}
            />
          )}
          
          {arregloDeArreglos.length >= 1 ? (
          <div
            className="uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l contentBlog"
            uk-grid="masonry: true"
          >
            {arregloDeArreglos[page - 1].map((item, index) => {
              return (
                <BlogCard
                  title={item.title}
                  key={index}
                  id={item.id}
                  categories={item.categories}
                  image={item.image}
                  subtitle={item.subtitle}
                  slug={item.slug}
                  date={item.date}
                />
              );
            })}
          </div>
        
        ) : (
          <div>More coming soon</div>
          )}
          {/* {size != "small" && <Pagination callback={callBackPage} nPage={page} arrayPages={arregloDeArreglos} />} */}
      </div>
    </div>
  );
};

BlogTemplate.defaultProps = {
  arrayTags: ["2023", "pilotos", "circuitos"],
  arrayFilters: ["Date", "popular"],
};

import React from "react";
import "./characteristic.css";
import { Text } from "../Text/Text";

export const Characteristic = ({ name, value, value2,colorLine, padding, T1, T2 }) => {
  return (
    <div
      className='containerCharacteristic'
      style={{ borderBottom: `1px solid ${colorLine}`, paddingBottom: padding }}
    >
      <Text size={T1} color='white'>
        {name}
      </Text>

      {(value2 && value2 !== 'undefined' ) ?
        <Text size={T2} color='red'>
            {value2}
        </Text>
        :
        <Text size={T2} color='gray'> --- </Text>
      }
      {(value && value != '100:00:00' ) ?
        <Text size={T2} color='white'>{value}</Text>
      :
        <Text size={T2} color='gray'> --- </Text>
      }
      
    </div>
  );
};

Characteristic.defaultProps = {
  name: "name",
  value: "value",
  colorLine: "#5B5B5B",
  padding: "0px",
  T1: "p-small",
  T2: "p-small",
};



export const reducer = (state, action) => {

    switch (action.type) {
      case "SET_YEAR":
        return {
          ...state,
          year:action.payload
        };
      case "SET_FORCELIVETRACK":
        return {
          ...state,
          livetrack:action.payload
        };
      case "SET_SEASON":
        return {
          ...state,
          season:action.payload
        };
      case "SET_SEASONS":
        return {
          ...state,
          seasons:action.payload
        };
      case "SET_NEWS":
        return {
          ...state,
          pages: {...state.pages ,news:{news:action.payload}}
        };
      case "SET_BLOGS":
        return {
          ...state,
          pages: {...state.pages ,blogs:{blogs:action.payload}}
        };
      case "SET_TAGS":
        return {
          ...state,
          pages: {...state.pages ,news:{...state.pages.news,tags:action.payload}}
        };
      case "SET_CIRCUITS":
        return {
          ...state,
          pages: {...state.pages ,circuits:action.payload}
        };
      case "SET_HOME":
        return {
          ...state,
          pages: {...state.pages ,home:action.payload}
        };
      case "SET_ABOUT":
        return {
          ...state,
          pages: {...state.pages ,about:action.payload}
        };
      case "SET_FAQS":
        return {
          ...state,
          pages: {...state.pages ,faqs:action.payload}
        };
      case "SET_LEGALS":
        return {
          ...state,
          pages: {...state.pages ,legals:action.payload}
        };
      case "SET_TEAMS":
        return {
          ...state,
          pages: {...state.pages ,teams:action.payload}
        };
      case "SET_DRIVERS":
        return {
          ...state,
          pages: {...state.pages ,drivers:action.payload}
        };
      case "SET_ROUNDS":
        return {
          ...state,
          pages: {...state.pages ,rounds:action.payload}
        };
      case "SET_GALLERY":
        return {
          ...state,
          gallery: {...state.gallery ,[action.teamTag]: action.payload}
        };
      case "SET_STREAMS":
        return {
          ...state,
          liveStreams: action.payload       
        };
      case "SET_PARTNERS":
        return {
          ...state,
          partners: action.payload       
        };
      case "SET_ERROR":
        return {
          ...state,
          error: action.payload.error,
          message: action.payload.message
        };
      case "SET_LOADING":
        return {
          ...state,
          loading: action.payload
        };
      case "SET_KAYAK":
        return {
          ...state,
          kayak: action.payload
        };

      default:
        return state;
    }
  };
  
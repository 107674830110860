import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { HomeTemplate } from '../components/templets/homeTemplate/HomeTemplate';
import {  getDrivers, getNews, getRounds, getStreams, getTeams , } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import iconVB from "../assets/logos/eurocup3VB.png";


export const HomePage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);
    const [year, setYear] = React.useState(state.season);

    useEffect(() => {
      const getInfo = async () => {
        await getRounds(dispatch,state,year);
        await getNews(dispatch,state);
        await getDrivers(dispatch,state,year);
        await getTeams(dispatch,state,year);
        await getStreams(dispatch,state);
      }
      getInfo();
    },[])

    useEffect(() => {
      if( state.pages.circuits?.[year] && state.pages.news && state.pages.drivers?.[year] && state.pages.teams?.[year] && state.liveStreams){
        setLoading(false);
      }
    },[state.pages.circuits , state.pages.news ,state.pages.drivers, state.pages.teams])


  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        :
        <>
        <Helmet>
          <title>Eurocup 3 - Official website of the Eurocup 3 championship</title>
          <meta name="description" content={'Official site of the european championship for the F3 formula, formula 3 racing since 2023'}/>
          <link rel="canonical" href={document.location} />
          <meta name="robots" content="index, follow"/>
          <meta property="og:title" content={'Eurocup 3 - Official website of the Eurocup 3 championship'}/>
          <meta property="og:type" content={"website"} />
          <meta property="og:image" content={iconVB} />
          <meta property="og:url" content={document.location} />
          <meta property="og:site_name" content="Eurocup 3 formula3 Championship" />
          <meta property="og:locale" content="en_GB" />
          <meta property="og:locale:alternate" content="en_US" />


        </Helmet>
        <HomeTemplate
            circuits={state.pages.circuits[year]}
            rounds={state.pages.rounds[year]}
            newsArray={state.pages.news?.news}
            drivers={state.pages.drivers[year]}
            teams={state.pages.teams[year]}
            steam={state.liveStreams}
            season={year}
        />
        </>
        }
    </>
  )
}

import React from "react";
import "./listNews.css";
import newsImage from "../../../../assets/image/NewsImage.jpg";
import { Buttons } from "../../../atoms/button/Button";
import { Text } from "../../../atoms/Text/Text";
import { NewsCard } from "../../cards/newsCard/NewsCard";
import { useNavigate } from "react-router-dom";

export const ListNews = ({ news, title, buttonTitle, categories }) => {
  const navegate = useNavigate();
  const newsList = news.filter((report) => {
    const newsCategory = report.category.map((categoryNews) => {
      if (categories.includes(categoryNews.slug?.replace(/_|#|-|@|<>/g, " "))) {
        return true;
      } else {
        return false;
      }
    });
    return newsCategory.includes(true);
  });
  if (newsList.length < 1) {
    return null;
  }
  return (
    <section className="ListNews">
      <div className="newsHeaderListNews">
        <Text size={"h2"} seo={"h2"}>
          {title}
        </Text>
        <Buttons type={"danger"} onclick={()=>{navegate("/news")}}>{buttonTitle}</Buttons>
      </div>
      <div className="uk-column-1-4@m newsListNews">
        {newsList.map((newItem, index) => {
          return (
            <NewsCard
              image={newItem.image}
              title={newItem.title}
              subtitle={newItem.subtitle}
              slug={newItem.slug}
              categories={newItem.category}
              date={newItem.date}
              size={newItem.size}
              id={newItem.id}
              key={index}
            />
          );
        })}
      </div>
    </section>
  );
};

ListNews.defaultProps = {
  title: "News",
  buttonTitle: "More News",
  news: [
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: "Categoria",
      date: "Dec 24, 2016",
    },
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: "Categoria",
      date: "Dec 24, 2016",
      size: "default",
    },
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: "Categoria",
      date: "Dec 24, 2016",
      size: "default",
    },
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: "Categoria",
      date: "Dec 24, 2016",
      size: "default",
    },
  ],
};

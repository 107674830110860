import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { TeamTemplate } from '../components/templets/teamTemplate/TeamTemplate';
import { getDrivers, getGallery, getNews, getRounds, getTeams } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import { getStandingForTeam } from '../utils/calculStandings';
import { convertToSlug } from '../utils/stringUtils';
import iconVB from "../assets/logos/eurocup3VB.png";


export const TeamPage = () => {
  const [state, dispatch] = useCup3();
  const [loading, setLoading] = React.useState(true);
  const [team, setTeam] = React.useState({});

  const [year, setYear] = React.useState(state.season);

  const { teamId } = useParams()
  //get basic info
  useEffect(() => {
    const getInfo = async () => {
      await getTeams(dispatch, state, year);
      await getDrivers(dispatch, state, year);
      await getRounds(dispatch, state, year);
      await getNews(dispatch, state);
    }
    getInfo();
  }, [])

  useEffect(() => {
    if (state.pages.rounds?.[year] && state.pages.drivers?.[year] && state.pages.teams?.[year] && state.pages.news) {
      const getGalleryInfo = async () => {
        const tagTeam = state.pages.teams[year].filter(team => convertToSlug(team.teamName) === teamId)[0].galleryTag
        await getGallery(dispatch, state, tagTeam);

      }
      getGalleryInfo();
      setTeam(state.pages.teams[year].find((team) => convertToSlug(team.teamName) === teamId))
      setLoading(false);
    }
  }, [state, dispatch])


  return (
    <>
      {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : <>
          <Helmet>
            <title>{`${team.acf.title ? team.acf.title : team.teamName} Team profile in Eurocup3`}</title>
            <meta name="title" content={`${team.acf.title ? team.acf.title : team.teamName} Team profile in Eurocup3 - The spanish formula 3 championship`} />
            <meta name="description" content={team.acf.description ? team.acf.description : `${team.acf.title ? team.acf.title : team.teamName} Team lineup in Eurocup3, based on ${team["base-city"]}, ${team.country}. ${team.teamDescription}` } />
            <link rel="canonical" href={document.location} />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content={`${team.acf.title ? team.acf.title : team.teamName} Team profile in Eurocup3`} />
            <meta property="og:type" content={"website"} />
            <meta property="og:image" content={iconVB} />
            <meta property="og:url" content={document.location} />
            <meta property="og:site_name" content="Eurocup 3 teams lineup for the 2024 season" />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:locale:alternate" content="en_US" />
          </Helmet>
          <TeamTemplate
            team={team}
            news={state.pages.news.news}
            allDrivers={state.pages.drivers[year]}
            imagesTeam={state.gallery}
            // round={getStandingForTeam(state.pages.rounds[year], state.pages.teams[year])[0]}
          />
        </>
      }
    </>
  )
}
import React from "react";
import "./infoRoundsDetails.css";

import { PosteDate } from "../../atoms/posteDate/PosteDate";
import { Icons } from "../../atoms/icons/Icons";
import { Text } from "../../atoms/Text/Text";
import { WeatherIcons } from "../../atoms/weatherIcons/WeatherIcons";

export const InfoRoundsDetails = ({ title,subtitle,date,podcast,weather,numRound ,latestRound}) => {
  return (
    <div className='infoRoundsDetails'>
      <div>
        {latestRound == numRound && 
          <div className='infoRoundsDetailsText'>
          <Text color={"gray"} size={"h6"}>
            {title}
          </Text>
        </div>}
        <div className='infoRoundsDetailsRound'>
          <Text color={"gray"} size={"heroSmall"}>
            {subtitle}
          </Text>
          <Text color={"white"} size={"heroSmall"}>
            {numRound}
          </Text>
        </div>
        <PosteDate date={date} />
      </div>
      <div className="iconsInfoRound">
        <WeatherIcons weather={weather} />
        {podcast && <Icons icon={"spotify"} size={"large"} onclick={()=>{window.open(podcast)}} button={true}/>}
      </div>
    </div>
  );
};

InfoRoundsDetails.defaultProps = {
  date: "12 nov",
  podcast: "",
  weather: "day-sunny",
  numRound: "1"
};

import React from "react";
import "./listContent.css";
import { Text } from "../../../atoms/Text/Text";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { convertToSlug } from "../../../../utils/stringUtils";

export const ListContent = ({ title, arrayLinks, className = "" }) => {
  const navigate = useNavigate();
  return (
    <div className={`blockListContent ${className}`}>
      <Text size="h6" color="white">
        {title}
      </Text>
      <div className="contentListContent">
        {arrayLinks.map((link, index) => {
          return (
            <div
              key={index}
              className="itemContentList"
              onClick={() => {
                navigate(link.onclick);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <Text  size="s2" color="white">
                {link.name}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ListContent.defaultProps = {
  title: "title",
  arrayLinks: [{ name: "link", onclick: () => console.log("click") }],
};

ListContent.propTypes = {
  title: PropTypes.string,
  arrayLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onclick: PropTypes.string,
    })
  ),
};

import React from 'react'
import './verticalNav.css'
import { PropTypes } from 'prop-types'
import { VerticalNavItem } from '../../../atoms/verticalNavItem/VerticalNavItem'
import resizeHandler from '../../../../utils/sizeScreen'

export const VerticalNav = ({array}) => {
  return (
    <ul className="uk-nav uk-nav-default" uk-scrollspy-nav="closest: li; scroll: true">
        {array.map((item, index) => {
            return (
                <li key={index} >
                    <VerticalNavItem round={item} href={`#round-${item}`} selected={item === 1 ?true:false}/>
                </li>
            )
        })}
    </ul>
  )
}

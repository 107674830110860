import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { ContactTemplate } from '../components/templets/contactTemplate/ContactTemplate';
import iconVB from "../assets/logos/eurocup3VB.png";



export const ContactPage = () => {
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setLoading( false);
    },[])


  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        :
        <>
            <Helmet>
              <title>Contact - Eurocup3</title>
              <meta name="description" content={"For partnerships, Information or If you are experiencing a problem or have a question,please contact us at: contact@eurocup3.org"} />
              <link rel="canonical" href={document.location} />
              <meta name="robots" content="index, follow"/>
              <meta property="og:title" content={'Contact - Eurocup3'}/>
              <meta property="og:type" content={"website"} />
              <meta property="og:image" content={iconVB} />
              <meta property="og:url" content={document.location} />
              <meta property="og:site_name" content="Contact - Eurocup3" />
              <meta property="og:locale" content="en_GB" />
              <meta property="og:locale:alternate" content="en_US" />
              <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
              <meta HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'/>
            </Helmet>
            <ContactTemplate/>
        </>
        }
    </>
  )
}

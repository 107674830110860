import React from 'react'
import './pilotCard.css'
import { Text } from '../../../atoms/Text/Text'
import { PropTypes } from 'prop-types'
import { FlagKit } from '../../../atoms/flagKit/FlagKit'
import { useNavigate } from 'react-router-dom'
import { convertToSlug } from '../../../../utils/stringUtils'

export const PilotCard = ({numPilot,flagPilot,imgPilot,namePilot,teamPilot,pilotId}) => {
    const navigate = useNavigate()
    const handlePilot = () => {
        if(imgPilot === "/image/default/pilot_empty.png") return
        navigate(`/driver/${convertToSlug(namePilot)}`)
        window.scrollTo(0, 0)
    }
  return (
    <div className='uk-card uk-card-default containPilotCard' onClick={handlePilot} style={{cursor: imgPilot === "/image/default/pilot_empty.png"?"not-allowed":"pointer",}}>
        <div className='topPilotCard'>
            {numPilot > 0  && 
            <div className='numPilotCard'>{ numPilot}</div>
            }
            <div className='flagPilotCard'><FlagKit country={flagPilot} /></div>
        </div>
        <div className='imagePilotCard uk-card-media-top'>
            <img src={imgPilot} alt="pilot" />
        </div>
        <div className='namePilotCard uk-card-body'>
            <div className='namePilotCardText'><Text size={"h6"} seo={"h3"}>{namePilot}</Text></div>
            <div className='teamsPilotCardText'><Text size={"b-small"}>{teamPilot}</Text></div>
        </div>
    </div>
  )
}

PilotCard.defaultProps = {
    numPilot: 2,
    flagPilot: "US",
    imgPilot: "/image/default/pilot_empty.png",
    namePilot: "Name Pilot",
    teamPilot: "Campos Racing"
}

PilotCard.propTypes = {
    numPilot: PropTypes.number,
    flagPilot: PropTypes.string,
    imgPilot: PropTypes.string,
    namePilot: PropTypes.string,
    teamPilot: PropTypes.string
}
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { DriverTemplate } from '../components/templets/driverTemplate/DriverTemplate';
import { getDrivers, getNews, getRounds, getTeams } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import { convertToSlug } from '../utils/stringUtils';
import iconVB from "../assets/logos/eurocup3VB.png";


export const DrivePage = () => {
  const [state, dispatch] = useCup3();
  const [loading, setLoading] = React.useState(true);
  const [driver, setDriver] = React.useState({});
  const [year, setYear] = React.useState(state.season);

  const {driverId} = useParams()

  useEffect(() => {
    const getInfo = async () => {
      await getDrivers(dispatch,state,year);
      await getRounds(dispatch,state,year)
      await getNews(dispatch,state);
      await getTeams(dispatch,state,year);
    }
    getInfo();
  },[])

  useEffect(() => {
    if(state.pages.drivers?.[year] && state.pages.rounds?.[year] && state.pages.teams?.[year] && state.pages.news){
      setDriver(state.pages.drivers?.[year].find((driver)=> convertToSlug(driver.driverName) == driverId))
      setLoading(false);
    }
  },[state,dispatch])

  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : <>
          <Helmet>
            <title>{`${driver.acf.meta_title ? driver.acf.meta_title : driver.driverName} Racing profile - Eurocup3 spanish formula 3 championship`}</title>
            <meta name="title" content={driver.acf.meta_title ? driver.acf.meta_title : `${driver.driverName} Driver's profile - Eurocup3`} />
            <meta name="description" content={`${driver.acf.meta_title ? driver.acf.meta_title : driver.driverName}, number ${driver.driverNum} in Eurocup3 statistics and details for the driver running for ${driver.country} on this season formula 3 spanish championship`} />
            <link rel="canonical" href={document.location} />
            <meta name="robots" content="index, follow"/>
            <meta property="og:title" content={`${driver.acf.meta_title ? driver.acf.meta_title : driver.driverName} Racing profile - Eurocup3 - the spanish formula 3 championship`}/>
            <meta property="og:type" content={"profile"} />
            <meta property="og:image" content={driver.driverCardImage.url ? driver.driverCardImage.url : iconVB} />
            <meta property="og:url" content={document.location} />
            <meta property="og:site_name" content={`${driver.acf.meta_title ? driver.acf.meta_title : driver.driverName} Driver's profile - Eurocup3`} />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:locale:alternate" content="en_US" />
            <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
            <meta HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'/>
          </Helmet>
          <DriverTemplate driver={driver} news={state.pages.news?.news} round={state.pages.rounds[year][0]} teams={state.pages.teams[year]}/>
        </>
        }
    </>
  )
}
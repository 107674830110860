import React, {  useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { AboutPostsTemplate } from '../components/templets/AboutPostsTemplate/AboutPostsTemplate';
import { getAbout } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import iconVB from "../assets/logos/eurocup3VB.png";


export const AboutPostsPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
      const getInfo = async () => {
        await getAbout(dispatch,state)
      }
      getInfo();
    },[])

    useEffect(() => {
      if(state.pages.about){
        setLoading(false);
      }
    },[state,dispatch])



  return (
    <div>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : 
        <div>
            <Helmet>
              <title>About Details - Eurocup 3</title>
              <meta name="description" content={'Details of the european F3 formula'}/>
              <link rel="canonical" href={document.location} />
              <meta name="robots" content="index, follow"/>
              <meta property="og:title" content={'About Details - Eurocup 3'}/>
              <meta property="og:type" content={"website"} />
              <meta property="og:image" content={iconVB} />
              <meta property="og:url" content={document.location} />
              <meta property="og:site_name" content="About the Eurocup 3 Championship" />
              <meta property="og:locale" content="en_GB" />
              <meta property="og:locale:alternate" content="en_US" />
            </Helmet>
            <AboutPostsTemplate
                titlesSideNav={"The EuroCup 3"}
                arrayAbout={state.pages.about}
                section={"about"}
            />
        </div>
        }
    </div>
  )
}

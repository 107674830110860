import React from "react";
import "./stream.css";
import text from "../../../assets/data/staticText.json";
import startingSoon from "../../../assets/image/startingSoon.jpg";

export const Streams = ({url}) => {
  const { streams } = text;
  return (
    <div style={{height:"100vh",width:"100%"}}>
      {url === ""?
      <div className="NoStream"><img src={startingSoon} alt={streams.NoStream}/></div>
      :<iframe
        width="100%"
        height="100%"
        src={url}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>}
    </div>
  );
};

import React from "react";
import "./teamsHeader.css";
import { Buttons } from "../../atoms/button/Button";
import { useNavigate } from "react-router-dom";
import { convertToSlug } from "../../../utils/stringUtils";
import { Text } from "../../atoms/Text/Text";

export const TeamsHeader = ({color,logo,carTeam,button}) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate(`/teams/${convertToSlug(button)}`)
    window.scrollTo({
      top: 0
    });
  }
  return (
    <div className="containTeamHeader">
      <div className="contentTeamHeader">
        <div className="colorTeamHeader" style={{backgroundColor: color}} ></div>
        <div className="iconTeamHeader">
          <img src={logo} alt="logoTeam" />
        </div>
        <div className="carTeamHeader">
          <img src={carTeam} alt="carTeam" />
        </div>
      </div>
      <div className="buttonTeamHeader">
        <Buttons type="danger" size="medium" onclick={handleClick}>
          <Text color={"white"} seo={"h2"}>
            {button}
          </Text>
        </Buttons>
      </div>
    </div>
  );
};

TeamsHeader.defaultProps = {
  color: "red",
  logo: "",
  carTeam: "/image/default/Car_empty.png",
  button: {name:"button",onclick:()=>{}}
}
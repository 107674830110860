import React from 'react'
import { Text } from '../../atoms/Text/Text'
import './pagination.css'

export const Pagination = ({arrayPages,callback,nPage}) => {
    const [page,setPage] = React.useState(1)

    const onChangePage = (page) => {
        if(1 > page || page > arrayPages.length) return;
        setPage(page)
        callback(page)
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    }
  return (
    <ul className="uk-pagination uk-flex-center pagination" >
        <li className="numPagination" onClick={()=>{onChangePage(nPage-1)}}> &lt; </li>
            {
                arrayPages.map((item,index) => {
                    return (
                        <li key={index} onClick={()=>{onChangePage(index+1)}} className={`numPagination ${index === page-1 ? "uk-active" : ""}`}><Text size='b-medium' color={index === page-1 ? "white" : "black"}>{index+1}</Text></li>
                    )
                })
            }
        <li className="numPagination" onClick={()=>{onChangePage(nPage+1)}} >&gt; </li>
    </ul>
  )
}

Pagination.defaultProps = {
    arrayPages: [1,2,3,4,5,6,7,8,9,10],
    callback: () => {},
    nPage: 1
}
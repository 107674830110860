import React, { useEffect } from "react";
import "./roundNav.css";
import { CircuitCard } from "../../cards/circuitCard/CircuitCard";
import { useNavigate } from "react-router-dom";
import resizeHandler from "../../../../utils/sizeScreen";
import { convertToSlug } from "../../../../utils/stringUtils";

export const RoundNav = ({ prev, next, callback,season }) => {
  const navigator = useNavigate();
  const [size, setSize] = React.useState("");
  const sizeScreen = () => { setSize(resizeHandler()) }
  useEffect(() => {
    sizeScreen();
  }, []);

window.addEventListener('resize', sizeScreen);
  return (
    <div className="roundNav">
      <div className="nextRound">
        {prev && (
          <div
            onClick={() => {
              navigator(`/round-detail/${season}/${convertToSlug(prev["circuit-display-name"])}`);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              callback(convertToSlug(prev["circuit-display-name"]));
            }}
            className="roundNavCard"
          >
           {size !== "small" && <CircuitCard
              imageBack={
                JSON.parse(decodeURIComponent(prev["track-background-image"]))
                  .url
              }
              circuitImage={
                JSON.parse(decodeURIComponent(prev["track-diagram-miniature"]))
                  .url
              }
              name={prev["circuit-display-name"].toLowerCase()}
              date={prev["date-display-text"]}
              round={prev["round-number"]}
              country={prev["country"]}
            />}
            <div className="nameNavRound">
              <span>{`<`}</span><div>Prev</div>
            </div>
          </div>
        )}
      </div>
      <div></div>
      <div className="nextRound">
        {next && (
          <div
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigator(`/round-detail/${season}/${convertToSlug(next["circuit-display-name"])}`);
              callback(convertToSlug(next["circuit-display-name"]));
            }}
            className="roundNavCard"
          >
            <div className="nameNavRound">
              <div>Next</div>
            </div>
            {size !== "small" && 
            <CircuitCard
              imageBack={
                JSON.parse(decodeURIComponent(next["track-background-image"]))
                  .url
              }
              circuitImage={
                JSON.parse(decodeURIComponent(next["track-diagram-miniature"]))
                  .url
              }
              name={next["circuit-display-name"].toLowerCase()}
              date={next["date-display-text"]}
              round={next["round-number"]}
              country={next["country"]}
            />
            }
          </div>
        )}
      </div>
    </div>
  );
};

import React from 'react'
import { WiDaySunny } from "react-icons/wi";
import { WiDayCloudy } from "react-icons/wi";
import { WiDayCloudyGusts } from "react-icons/wi";
import { WiDayFog } from "react-icons/wi";
import { WiDayHail } from "react-icons/wi";
import { WiDayLightning } from "react-icons/wi";
import { WiDayRain } from "react-icons/wi";
import { WiDayRainWind } from "react-icons/wi";
import { WiDayShowers } from "react-icons/wi";
import { WiDaySnow } from "react-icons/wi";
import { WiDayWindy } from "react-icons/wi";
import { WiHot } from "react-icons/wi";
import { WiDayLightWind } from "react-icons/wi";
import { WiCloud } from "react-icons/wi";
import { WiCloudyGusts } from "react-icons/wi";
import { WiFog } from "react-icons/wi";
import { WiRain } from "react-icons/wi";
import { WiRainWind } from "react-icons/wi";
import { WiSnow } from "react-icons/wi";
import { WiSnowWind } from "react-icons/wi";
import { WiLightning } from "react-icons/wi";
import { WiWindy } from "react-icons/wi";
import { WiStrongWind } from "react-icons/wi";

export const WeatherIcons = ({weather}) => {
  const style = {
    backgroundColor:"#4EBCD4",borderRadius:"10px", color:"white"
  }
    switch(weather){
      case "wi-day-sunny":
        return <WiDaySunny size={40} style={style}/>
      case "wi-day-cloudy":
        return <WiDayCloudy size={40} style={style}/>
      case "wi-day-cloudy-gusts":
        return <WiDayCloudyGusts size={40} style={style}/>
      case "wi-day-fog":
        return <WiDayFog size={40} style={style}/>
      case "wi-day-hail":
        return <WiDayHail size={40} style={style}/>
      case "wi-day-lightning":
        return <WiDayLightning size={40} style={style}/>
      case "wi-day-rain":
        return <WiDayRain size={40} style={style}/>
      case "wi-day-rain-wind":
        return <WiDayRainWind size={40} style={style}/>
      case "wi-day-showers":
        return <WiDayShowers size={40} style={style}/>
      case "wi-day-snow":
        return <WiDaySnow size={40} style={style}/>
      case "wi-day-windy":
        return <WiDayWindy size={40} style={style}/>
      case "hot":
        return <WiHot size={40} style={style}/>
      case "wi-day-light-wind":
        return <WiDayLightWind size={40} style={style}/>
      case "wi-cloud":
        return <WiCloud size={40} style={style}/>
      case "wi-cloudy-gusts":
        return <WiCloudyGusts size={40} style={style}/>
      case "wi-fog":
        return <WiFog size={40} style={style}/>
      case "wi-rain":
        return <WiRain size={40} style={style}/>
      case "wi-rain-wind":
        return <WiRainWind size={40} style={style}/>
      case "wi-snow":
        return <WiSnow size={40} style={style}/>
      case "wi-snow-wind":
        return <WiSnowWind size={40} style={style}/>
      case "wi-lightning":
        return <WiLightning size={40} style={style}/>
      case "wi-windy":
        return <WiWindy size={40} style={style}/>
      case "wi-strong-wind":
        return <WiStrongWind size={40} style={style}/>
      default:
        return <WiDaySunny size={40} style={style}/>
    }
}

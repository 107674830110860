import React, { useEffect } from 'react'

import { PostTemplate } from '../components/templets/postTemplate/PostTemplate';
import { getBlogs } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';



export const PostPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const getInfo = async ()=> {
          await getBlogs(dispatch,state);
        }
        getInfo();
    }, [])

    useEffect(() => {
      if(state.pages.blogs){
        setLoading(false);
      }
    },[state,dispatch])

  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        :
          <PostTemplate
              newList={state.pages.blogs.blogs}
          />
        }
    </>
  )
}

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { RoundDetailTemplate } from '../components/templets/roundDetailTemplate/RoundDetailTemplate';

import {  getDrivers, getNews, getRounds, getTeams} from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import iconVB from "../assets/logos/eurocup3VB.png";

//console.log('Is circuit')

export const RoundDetailPage = () => {
    const [state, dispatch] = useCup3();
    const [loading, setLoading] = React.useState(true);
    const [meta, setMeta] = React.useState({});

    const {yearRound,roundId} = useParams()
    const [year, setYear] = React.useState(yearRound);


    useEffect(() => {
        const getInfo = async ()=> {
          await getRounds(dispatch,state,year);
          await getDrivers(dispatch,state,year); 
          await getTeams(dispatch,state,year); 
          await getNews(dispatch,state);
        }
        getInfo();
      }, [])

      useEffect(() => {
        if(state.pages.rounds?.[year] && state.pages.circuits?.[year] && state.pages.news && state.pages.drivers?.[year] && state.pages.teams?.[year]){
          const meta =(state.pages.circuits[year].find((round) => round.slug == roundId))
          setMeta(meta.acf)
          setLoading(false);
        }
      },[state,dispatch])

  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        :
        <>
            <Helmet>
              <title>{meta.title}</title>
              <meta name="title" content={meta.meta_title} />
              <meta name="description" content={meta.meta_description} />
              <link rel="canonical" href={document.location} />
              <meta name="robots" content="index, follow"/>
              <meta property="og:title" content={`${roundId} round - Eurocup3`}/>
              <meta property="og:type" content={"article"} />
              <meta property="og:image" content={iconVB} />
              <meta property="og:url" content={document.location} />
              <meta property="og:site_name" content="Eurocup 3 calendar season 2024 Championship" />
              <meta property="og:locale" content="en_GB" />
              <meta property="og:locale:alternate" content="en_US" />
            </Helmet>
            <RoundDetailTemplate circuits={state.pages.circuits[year]} news={state.pages.news?.news} roundId={roundId} rounds={state.pages.rounds[year]} drivers={state.pages.drivers[year]} teams={state.pages.teams[year]} season={year} />
        </>
        }
    </>
  )
}

export const convertDate = (date) => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = newDate.getMonth() 
    var monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = newDate.getDate()

    return `${monthArray[month]}, ${day} ${year}`
}

export const convertTime = (time) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const newTime = new Date(time)
    const hours = newTime.getHours()
    const minutes = newTime.getMinutes()
    var dayName = days[newTime.getDay()];
    return `${dayName}, ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const convertRaceDate = (time) => {
    const newTime = new Date(time)
    const year = newTime.getFullYear()
    const hours = newTime.getHours()
    const minutes = newTime.getMinutes()
    const month = newTime.getMonth() 
    var monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = newTime.getDate()

    if (year < 2024) return null
    return (
        <div className="schtime">
            <div>{`${monthArray[month]}, ${day}`}</div>
            {`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`}
        </div>
    )
}
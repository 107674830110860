import React from 'react'
import "./newsTemplate.css"

import text from '../../../assets/data/staticText.json' 
import { NewsSection } from '../../organisms/NewsSection/NewsSection'

import newsImage from "../../../assets/image/NewsImage.jpg";
import { Pagination } from '../../molecules/pagination/Pagination';
import { FilterSection } from '../../organisms/filterSection/FilterSection';
import resizeHandler from '../../../utils/sizeScreen';
import { Text } from '../../atoms/Text/Text';
import { useParams } from 'react-router-dom';


export const NewsTemplate = ({news,arrayTags,arrayFilters}) => {
    const {news:textNews} = text
    const param = useParams();

    const [page,setPage] = React.useState(1);
    const [tags, setTags] = React.useState(param.tag? param.tag.toString():arrayTags.map((item) => {return  item.slug}));
    const [newsF, setNewsF] = React.useState(news);
    const [size, setSize] = React.useState("");
    const sizeScreen = () => { setSize(resizeHandler()) }
    React.useEffect(() => {
      sizeScreen();
    }, []);

    React.useEffect(() => {
        if(tags.length === 0){
            setTags(arrayTags.map((item) => {return  item.slug}));
        }
        const newsFiltrate =news.filter((item) => {
            const isCategory = item.category.map(element => {
                if(tags.includes(element.slug)){
                    return true;
                }
                else{
                    return false;
                }
            });
            if(isCategory.includes(true)){
                return true;
            }
        });
        setNewsF(newsFiltrate);
    }, [tags,news]);

  window.addEventListener('resize', sizeScreen);
  
    const callBackPage = (a) => {
        setPage(a);
    }

    const arregloDeArreglos = []; // Aquí almacenamos los nuevos arreglos
    const LONGITUD_PEDAZOS = 10; 

    for (let i = 0; i < newsF.length; i += LONGITUD_PEDAZOS) {
        let pedazo = newsF.slice(i, i + LONGITUD_PEDAZOS);
        arregloDeArreglos.push(pedazo);
    }

  return (
    <div>
        {
        arregloDeArreglos.length >= 1 ? 
        <div className='contentNewsTemplate'>
            <div className='contentNewsTitle'>
             <Text size='h1' seo={"h1"}>{textNews.titleS1}</Text>
            </div>
            <FilterSection  arrayTags={arrayTags.map((item) => {return  item.slug})} callbackTags={setTags} />
            <NewsSection news={arregloDeArreglos[page-1]} section={false} firstImage="full"/>
            {size != "small" && <Pagination callback={callBackPage} nPage={page} arrayPages={arregloDeArreglos} />}
        </div>
        :
        <div className='contentNewsTemplate'>
            <div className='contentNewsTitle'>
             <Text size='h1' seo={"h1"}>{textNews.titleS1}</Text>
            </div>
            <FilterSection  arrayTags={arrayTags.map((item) => {return  item.slug})} callbackTags={setTags} />
            <h1>No hay noticias</h1>
        </div>
        }
    </div>
  )
}




const newsArray = [  
    
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        categories:"Categoria",
        date:"Dec 24, 2016",
        size:"default"
    },
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        categories:"Categoria",
        date:"Dec 24, 2016",
        size:"default"
    },
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        categories:"Categoria",
        date:"Dec 24, 2016",
        size:"default"
    },
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        categories:"Categoria",
        date:"Dec 24, 2016",
        size:"default"
    },
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        categories:"Categoria",
        date:"Dec 24, 2016",
        size:"default"
    },
]


NewsTemplate.defaultProps = {
    news: newsArray,
    arrayTags: ["2023","pilotos","circuitos","autos"],
    arrayFilters: ["Date","popular"]
}

import React from 'react'
import './winPilotCard.css'
import { Text } from '../../../atoms/Text/Text'
import { Cover } from 'uikit-react'
import { FlagKit } from '../../../atoms/flagKit/FlagKit'
// import pilot from '/image/default/pilot_empty.png'

export const WinPilotCard = ({nameRace,winner,namePilot,countryPilot,teamPilot,timeLap,carImage,pilotImage,reverse}) => {
  return (
    <div className= {`winPilotCardContainer uk-container-small uk-grid ${reverse?"reverse":""}`}>
      <div className='infoPilotWin'>
        <div className='trackWinner'>
          <Text size="heroSmall" color="white" >{nameRace}</Text>
          <Text size="h5" color="white" >{winner}</Text>
        </div>
        <div className='nameWinner'>
          <div className='namePilotWinner'>
            <FlagKit country={countryPilot}/>
            <Text size="h4" color="white" >{namePilot}</Text>
          </div>
          <Text size="s1" color="white" >{teamPilot}</Text>
        </div>
        <div className='timeWinner'>
          <Text size="p" color="white" >{timeLap}</Text>
        </div>
        <div className='carWinner'>
          <img src={carImage} />
        </div>
      </div>
      <div className='imageWinPilot'>
        <Cover type="image" src={pilotImage} height="medium"/>
      </div>
    </div>
  )
}

WinPilotCard.defaultProps = {
  reverse: false,
  nameRace:"FEATURE RACE",
  winner:"Winner",
  namePilot:"Not defined",
  teamPilot:"",
  timeLap:"100:00:00",
  carImage: "/image/default/Car_empty.png",
  pilotImage: "/image/default/pilot_empty.png"
}
import axios from 'axios';
import React, { useEffect } from 'react'
import { Footer } from '../components/organisms/footer/Footer';
import { NavBar } from '../components/organisms/navBar/NavBar';
import { useCup3 } from '../context/euroCup3/cup3State';
import {  getActualSeason, getPartners, getSeasons, getStreams, getYear } from '../context/euroCup3/cup3Action';

export const DefaultPage = ({children}) => {
  const [state, dispatch] = useCup3();
    const [data, setData] = React.useState(undefined);
    const [loading, setLoading] = React.useState(true);



    useEffect(() => {
      const getInfo = async () => {
        const dataNav = await axios.get("/data/nav&foot.json")
        setData(dataNav.data);
        // await getKayak(dispatch,state);
        await getPartners(dispatch,state);
        await getStreams(dispatch,state);
        await getYear(dispatch,state);
        await getSeasons(dispatch,state);
      }
      getInfo();
    },[])

    useEffect(() => {
      if( data !== undefined && state.liveStreams && state.partners &&  state.seasons ){
          const season =state.seasons.filter(season => season.name == state.year)[0].id; 
          getActualSeason(dispatch,state,season);

          setLoading(false);
      }
    },[state.liveStreams ,state.partners , state.year , state.seasons,dispatch,data])

  return (
    <>
        {loading ?
        "":
        <div>
            <NavBar
              arrayNav={data.navLinks.links}
              socialIcons={data.social.logos_social}
              streams={state.liveStreams}
              seasons={state.seasons}
              season={state.year}
            />
            {children}
            <Footer
              partners={state.partners}
              ListContent1={data.listFooter1}
              ListContent2={data.listFooter2}
              newsletter={data.NewsletterFooter}
              socialIcons={data.social.logos_social}
              bottomFoot={data.bottomFooter}
              kayak={state.kayak}
            />
        </div>
        }
    </>
  )
}

import React from 'react'
import "./socialIcons.css"
import { Icons } from '../../atoms/icons/Icons'

export const SocialIcons = ({arrayIcons,size,gap,className,negative }) => {
  return (
    <div className={`socialDiv ${className}` }style={{gap: gap}}>
      {
        arrayIcons.map((icon,index) => {
          return (
            icon.url != '' ? <Icons key={index} icon={icon.logo} negative={negative} size={size} button={true} onclick={()=>{window.open(icon.url, '_blank')}}/> : ""
          )
        })
      }
    </div>
  )
}

SocialIcons.defaultProps = {
  arrayIcons: [
    {
      logo: 'facebook',
      url: () => {}
    },
    {
      logo: 'twitter',
      url: () => {}
    },
    {
      logo: 'instagram',
      url: () => {}
    },
    {
      logo: 'youtube',
      url: () => {}
    },
    {
      logo: 'linkedin',
      url: () => {}
    }
  ],
  size: "medium",
  gap: "15px",
  negative: true,
  className: ""
}
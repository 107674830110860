import React from 'react'
import { FlagKit } from '../flagKit/FlagKit'
import { Text } from '../Text/Text'
import './pilotPointsStanding.css'
import rookies from '../../../assets/image/rookie_icon.svg'

export const PilotPointsStanding = ({
  positionDriver,
  nameDriver,
  countryDriver,
  pointsDriver,
  isRookie
}) => {
  return (
    <tr className='filaTableStandings' >
      <td>
        <Text size={"c1"} >{positionDriver}</Text>
      </td>
      <td >
          <FlagKit country={countryDriver}/>
      </td>
      <td className='nameStandings'>
        {isRookie && <img src={rookies} height={10} width={10}/>}
        <Text size={"c2"}>{nameDriver}</Text>
      </td>
      <td className='pointsStandingsTable' >
        <Text  size={"c1"} >{pointsDriver}</Text>
      </td>
    </tr>
  )
}

PilotPointsStanding.defaultProps = {
  positionDriver: 0,
  nameDriver: "nombre Piloto",
  countryDriver: "ES",
  pointsDriver: 0,
}
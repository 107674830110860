import React, { useEffect, useMemo, useState } from "react";
import "./teamTemplate.css";
import text from '../../../assets/data/staticText.json' 
import newsImage from "../../../assets/image/NewsImage.jpg";
import { Text } from "../../atoms/Text/Text";
import { PilotCard } from "../../molecules/cards/pilotCard/PilotCard";
import { Gallery } from "../../molecules/gallery/Gallery";
import { TeamInfo } from "../../organisms/teamInfo/TeamInfo";
import { ListNews } from "../../molecules/lists/listNews/ListNews";
import { Grid } from "uikit-react";
import { useCup3 } from "../../../context/euroCup3/cup3State";

export const TeamTemplate = ({ team, news, allDrivers,imagesTeam ,round}) => {
  const [state] = useCup3();
  const { team: teamText } = text;
  const year = useMemo(() => state.season, [state.season])
  const [teamDrivers, setTeamDrivers] = useState([]);
 
  useEffect(() => {
    const fetchDrivers = async () => {
      await team["teamSeasonLineup"].find((season) => {
        let drivers = []
        season?.['ts-drivers'] && season?.['ts-drivers']?.forEach((driverId) => {
          const driver = allDrivers.find((driver) => driver.driverId === Number(driverId))
          drivers.push(driver)
        })
        return setTeamDrivers(drivers)
      })
    }
    fetchDrivers()
  }, [allDrivers, team, year])

  return (
    <div className="teamTemplate">
      <TeamInfo
        name={team.teamShortName}
        subName={team.teamName}
        colorTeam={team.teamColor}
        logo={team.teamImage?.url}
        car={team["car-image"] ? team["car-image"].url : undefined}
        socialIcons={team.socialIcons}
        char1={{ name: "Standings", value: round?.totalStandingTeams.indexOf((team.teamId).toString()) === -1 ? "-" : round?.totalStandingTeams.indexOf((team.teamId).toString())+ 1 }}
        char2={{ name: "points", value: round?.totalPointsTeams[team.teamId]? round?.totalPointsTeams[team.teamId].total : "-" }}
        country={team.country}
        base={team["base-city"]}
        debut={team.debut}
        status={team.status !== "future"}
      />
      <section className="teamTemplatesS1">
        <Text size={"h2"} seo={"h2"}>
        {teamText.titleS2}
        </Text>
        <Grid className=" driversTeamTemplate">
          {teamDrivers?.map((pilot, index) => {
            if (!pilot) return null;
            return (
              <div key={index} className="listPilotCardTeam uk-child-width-1-2@s uk-child-width-1-4@m">
                <PilotCard 
                  numPilot={pilot.driverNum}
                  flagPilot={pilot.country}
                  imgPilot={pilot.driverCardImage? pilot.driverCardImage.url : undefined}
                  namePilot={pilot.driverName}
                  teamPilot={team.teamShortName}
                  pilotId={pilot.driverId}
                />
              </div>
            );
          })}
        </Grid>
      </section>
      <section className="teamsTemplatesS11">
        <Text size={"p"} seo={"p"}>
          <div dangerouslySetInnerHTML={{ __html: team.teamDescription}} className='uk-width-expand@m textHistory'></div>
        </Text>
      </section>
      <section className="teamTemplatesS2">
        <Gallery images={imagesTeam[team.galleryTag]?imagesTeam[team.galleryTag]:[]} />
      </section>
      <ListNews news={news} categories={[team.teamShortName.toLowerCase(),team.teamName.toLowerCase()]}/>
    </div>
  );
};

TeamTemplate.defaultProps = {
  team: {},
  news: [
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: ["Categoria", "Categoria", "Categoria"],
      date: "Dec 24, 2016",
    },
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: ["Categoria"],
      date: "Dec 24, 2016",
      size: "default",
    },
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: ["Categoria"],
      date: "Dec 24, 2016",
      size: "default",
    },
    {
      image: newsImage,
      title: "Believe In Your Print Skills But Never Stop Improving",
      subtitle:
        "Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
      categories: ["Categoria"],
      date: "Dec 24, 2016",
      size: "default",
    },
  ],
};

import React from "react";
import { Text } from "../../atoms/Text/Text";
import { SocialIcons } from "../../molecules/socialIcons/SocialIcons";
import "./teamInfo.css";

import { StatsEuroCup3 } from "../../atoms/statsEuroCup3/StatsEuroCup3";
import { Characteristic } from "../../atoms/characteristic/Characteristic";
import { FlagKit } from "../../atoms/flagKit/FlagKit";
import { Cover, Grid } from "uikit-react";

export const TeamInfo = ({
  name,
  subName,
  socialIcons,
  colorTeam,
  char1,
  char2,
  country,
  base,
  debut,
  logo,
  car,
  status,
}) => {
  return (
    <div className="teamInfo">
      <div className="teamContent">
        <div className="teamContentAlign">
          <Grid className="teamContent1">
            <div className="uk-width-1-1 uk-width-1-2@s teamContentTitle" style={{borderColor: colorTeam}}>
              <Text size={"h3"} seo={"h2"} color="white">
                {name}
              </Text>
              <Text size={"p"} seo={"h1"} color="white">
                {subName}
              </Text>
            </div>
            <div className="uk-width-1-1 uk-width-1-2@s uk-align-center">
              <SocialIcons size={"small"} arrayIcons={socialIcons} />
            </div>
          </Grid>
          <Grid className="teamContent2 uk-flex-between" >
              <Cover type="image" src={car} alt="carTeam" className="uk-width-1-2@s" responsive={true} />
            <div className="uk-width-1-4@s trackInfoTeamContent">

                  <StatsEuroCup3 year={2024}/>
                  <div className="trackInfoTeamChar">
                    <Characteristic name={char1.name} value={char1.value} T1={"p-large"}  T2={"trackName"}/>
                    <Characteristic name={char2.name} value={char2.value} T1={"p-large"}  T2={"trackName"}/>
                  </div>

            </div>
          </Grid>
        </div>
      </div>
      <div className="teamDetails">
        <div className="teamDetails1">
          <FlagKit country={country} />
          <Text size={"p"} color="white">
            {country}
          </Text>
        </div>
        <div className="teamDetails2">
          <Text size={"p"} color="red">
            Base
          </Text>
          <Text size={"p"} color="white">
            {base}
          </Text>
        </div>
        <div className="teamDetails3">
          <Text size={"p"} color="red">
            Debut
          </Text>
          <Text size={"p"} color="white">
            {debut}
          </Text>
        </div>
      </div>
    </div>
  );
};

TeamInfo.defaultProps = {
  name: "Team Name",
  subName: "Team Subname",
  socialIcons: [
    {
      logo: "facebook",
      url: "https://www.facebook.com/",
    },
    {
      logo: "twitter",
      url: "https://twitter.com/",
    },
    {
      logo: "instagram",
      url: "https://www.instagram.com/",
    },
    {
      logo: "youtube",
      url: "https://www.youtube.com/",
    },
  ],
  char1: {
    name: "char1",
    value: "value1",
  },
  char2: {
    name: "char2",
    value: "value2",
  },
  country: "ES",
  base: "Barcelona",
  debut: "2020",
  logo:"",
  car: "/image/default/Car_empty.png",

};

export const abbreviateName = (fullName,redux= false)=>{

  const arr = fullName.split(" ")
  const name = arr.shift().charAt(0)
  const subName = arr.join(" ")
  // if(redux) return `${name}. ${subName}`
  // if(subName.length > 9) return `${name}. ${subName.substring(0,8)}...`
  return `${name}. ${subName}`
}

export const convertToSlug = (Text)=>{
  return Text
    .toLowerCase()
    .replace(/[^\w ]+/g,'')
    .replace(/ +/g,'-')
    ;
}
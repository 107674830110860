import React from 'react'
import "./filterSection.css"

import { Selector } from '../../atoms/selector/Selector'
import { SelectorTags } from '../../molecules/SelectorTags/SelectorTags'

export const FilterSection = ({arrayTags,arrayFilter,callbackTags,callbackFilter}) => {

  return (
    <div className='filterSection uk-container uk-margin-auto'>
        <SelectorTags arraySelect={arrayTags} callback={callbackTags}/>
        {/* <div className='selectFilter'>
            <label>Filter By:</label>
            <Selector arraySelect={arrayFilter}  callback={callbackFilter}/>
        </div> */}
    </div>
  )
}

FilterSection.defaultProps = {
    arrayTags: [],
    arrayFilter: [],
    callbackTags: () => {},
    callbackFilter: () => {}
}
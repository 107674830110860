import React, { useEffect } from "react";
import "./circuitSection.css";
import text from '../../../assets/data/staticText.json' 

import { PropTypes } from "prop-types";
import { InfoCircuitRound } from "../../molecules/infoCircuitRound/InfoCircuitRound";
import { TrackDetail } from "../../molecules/trackDetails/TrackDetail";
import { ListCircuits } from "../../molecules/lists/ListCircuits/ListCircuits";
import { Buttons } from "../../atoms/button/Button";
import { useNavigate } from "react-router-dom";
import { convertToSlug } from "../../../utils/stringUtils";
import { PilotsStanding } from "../../atoms/pilotsStanding/PilotsStanding";
import { Lightbox, LightboxItem } from "uikit-react";
import { winSta } from "../../../utils/calculStandings";
import { Text } from "../../atoms/Text/Text";
import { convertTime } from "../../../utils/dateConvert";

export const CircuitSection = ({
  circuits,
  list,// lista visble
  backList,//fondo negro de la lista
  callback,
  roundCircuit,//numero de la ronda del circuito
  roundDetail,//se puede ver el boton de ver mas detalles
  rounds,
  drivers,
  teams,
  season,
  onclick = ()=>{}
}) => {
  const navegate = useNavigate()
  const { calendar } = text;
  const [roundCircuitState, setRoundCircuitState] = React.useState(Number(roundCircuit)-1)

  const circuitsArray = circuits.map((circuit) => {
    return { ...circuit, onclick: () => {
      setRoundCircuitState(circuit.round-1)
      onclick(circuit)
    } };
  });
  
  const circuitDetails =calendar.values.map((detail,index) => {
    return {
      name: detail.value,
      value: circuits[roundCircuitState][detail.detail],
    };
  })
  const standingPilots = ()=>{
    let round
    if(circuits[roundCircuitState].status === "past" && rounds !== undefined){
      round = rounds.find((round) => round["round-number"] === circuits[roundCircuitState].round)

      if(round === undefined){
        round = {
          rankDrivers:[
            {driverId: '15573', total: 0},
            {driverId: '15574', total: 0},
            {driverId: '15575', total: 0}
          ]
        }
      }
      return winSta(drivers,teams,rounds,round)
    }
  }


  useEffect(() => {
    setRoundCircuitState(Number(roundCircuit)-1);
  }, [roundCircuit]);

  useEffect(() => {
    callback(roundCircuitState);
  }, [roundCircuitState, callback]);
  return (
    <div className='circuitSection'>
      <div
        style={{backgroundImage: `url(${circuits[roundCircuitState].circuitImageBack?.url})` }}
        className={`circuitSectionImage uk-background-cover`}
        uk-parallax='bgy: -200'
      >
        <div className='uk-container uk-container-large'>
          <div className='nux'>
            <div className='circuitSection1'>
          
              <InfoCircuitRound
                round={circuits[roundCircuitState].round}
                country={circuits[roundCircuitState].country}
                flag={circuits[roundCircuitState].bigFlag}
                name={circuits[roundCircuitState].name}
                date={circuits[roundCircuitState].date}
              />
              {
                (circuits[roundCircuitState].status === "past" || circuits[roundCircuitState].status === "present" ) && roundDetail == false? 
                <Buttons type={"danger"}  onclick={()=>{navegate(`/round-detail/${season}/${convertToSlug(circuits[roundCircuitState].name)}`)}} >View Round</Buttons>
                :
               null
              }
            </div>
          </div>
          <div className='nuz'>
            <div className='circuit-section__details uk-grid-collapse uk-child-width-1-2@m uk-grid'>
              <div className='imageCircuit'>
                <img
                  src={circuits[roundCircuitState].circuitImageLarge?.url}
                  alt='circuit'
                />
              </div>
              {
                circuits[roundCircuitState].status === "past"  && rounds !== undefined ?
                <div className="calendarWinRoundsPilots">
                  <Text size={"h4"} color={"white"}>Standings after the round</Text>
              {   
                standingPilots().map((pilot,index)=>{
                  return <PilotsStanding 
                  key={index}
                  number={pilot.numDriver}
                  namePilot={pilot.name}
                  teamPilot={pilot.team}
                  scorePilot={pilot.points}
                  teamColor={pilot.teamColor}
                />
                })
              }
              </div>
                  :
              <TrackDetail detailsArray={circuitDetails} circuit={circuits[roundCircuitState]} />
              }
            </div>
          </div>
        </div>
        {list ? (
          <div className={`circuitSection3 ${backList ? "backWhite" : ""}`}>
            <ListCircuits array={circuitsArray} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
const circuitsArrays = {
  circuitsInfo: {
    spa: {
      name: "spa",
      fullName: "Spa-Francorchamps",
      country: "belgium",
      date: "5 - 7 May",
      circuitImage: "/image/circuits/spa.png",
      round: 1,
      opened: 1920,
      length: 7.004,
      turns: 19,
      timeZone: "UTC+1",
      lap_record: "1:46.286",
      record_holder: "V.Bottas 2018, Formula 1",
      description: "",
    },
  },
};

CircuitSection.defaultProps = {
  circuits: circuitsArrays,
  roundCircuit: 0,
  list: false,
  backList: true,
  callback: () => {},
  roundDetail: false
};

// CircuitSection.propTypes = {
//   circuits: PropTypes.array,
//   nameCircuit: PropTypes.string,
//   list: PropTypes.bool,
//   backList: PropTypes.bool,
//   callback: PropTypes.func,
// };

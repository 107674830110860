
const socialsShare = ['facebook','twitter','pinterest','linkedin'];

export function shareSocialLinks(url,socials=socialsShare){
  const socialsShare = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    twitter: `https://twitter.com/intent/tweet?url=${url}`,
    pinterest: `https://pinterest.com/pin/create/button/?url=${url}`,
    linkedin: `https://www.linkedin.com/shareArticle?url=${url}`
  }
  return socials.map((social,index) => {
    return {
      logo: social,
      url: socialsShare[social]
    }
  })
}
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { CalendarTemplate } from '../components/templets/calendarTemplate/CalendarTemplate';
import { getDrivers, getRounds, getTeams } from '../context/euroCup3/cup3Action';
import { useCup3 } from '../context/euroCup3/cup3State';
import { useParams } from 'react-router-dom';
import iconVB from "../assets/logos/eurocup3VB.png";


export const CalendarPage = () => {
  const [state, dispatch] = useCup3();
  const [loading, setLoading] = React.useState(true);

  const calendarYear = useParams().year;


  const [year, setYear] = React.useState(  state.seasons.filter((season) => {if(season.slug == calendarYear) return season})[0].id)

  useEffect(() => {
    const getInfo = async () => {
      await getRounds(dispatch,state,year);
      await getDrivers(dispatch,state,year);
      await getTeams(dispatch,state,year);
    }
    getInfo();
  },[])

  useEffect(() => {
    if(state.pages.circuits?.[year] && state.pages.rounds?.[year] && state.pages.drivers?.[year] && state.pages.teams?.[year]){
      setLoading(false);
    }
  },[state,dispatch])



  return (
    <>
        {loading ?
        <div className='loading-spinner'><span uk-spinner="ratio: 4.5"></span></div>
        : <>
        <Helmet>
          <title>Season Calendar - Eurocup 3</title>
          <meta name="description" content={'Season 2024 Calendar with all the rounds, european F3 formula, availed by the Spanish racing association'}/>
          <link rel="canonical" href={document.location} />
          <meta name="robots" content="index, follow"/>
          <meta property="og:title" content={'Season Calendar - Eurocup 3'}/>
          <meta property="og:type" content={"website"} />
          <meta property="og:image" content={iconVB} />
          <meta property="og:url" content={document.location} />
          <meta property="og:site_name" content="Eurocup 3 calendar season 2024 Championship" />
          <meta property="og:locale" content="en_GB" />
          <meta property="og:locale:alternate" content="en_US" />

        </Helmet>
              <CalendarTemplate
                circuits={state.pages.circuits[year]}
                round={state.pages.rounds[year]}
                drivers={state.pages.drivers[year]}
                teams={state.pages.teams[year]}
                season={year}
              />
        </>
        }
    </>
  )
}

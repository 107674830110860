import React from 'react'
import "./flagKit.css"
import flagsNames from "../../../assets/data/flags.json";
import { FlagIcon } from "react-flag-kit";



export const FlagKit = ({country,size}) => {
  if(country === "") return <div className='nullFlag' style={{width:size, height:(size-9)}} ></div>;
  if(flagsNames[country] === undefined) return <img src={"../../../../public/image/default/default_flag.svg"} alt="FIA" className='nullFlag' style={{width:size, height:(size-9)}} />;
  return (
    <FlagIcon code={country} size={size} />
  )
}

FlagKit.defaultProps = {
  country: "ES",
  size: 32,
};

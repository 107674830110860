import React, { useEffect } from "react";
import "./navTableResults.css";
import { Text } from "../../../atoms/Text/Text";
import { Selector } from "../../../atoms/selector/Selector";
import resizeHandler from "../../../../utils/sizeScreen";

/*
The list of Tabs is detailed on: 'assets/data/staticText.json'
rookies tab is deactivated, original is
"Nav":["Drivers","Teams","Rookies"],
*/

export const NavTableResults = ({ categories,callback ,responsive}) => {
  const [size, setSize] = React.useState("");
  const sizeScreen = () => { setSize(resizeHandler()) }
  useEffect(() => {
    sizeScreen();
  }, []);

window.addEventListener('resize', sizeScreen);


  const [active, setActive] = React.useState(0);
  useEffect(() => {
    callback(active);
  }, [active]);
  if(responsive){
    if(size == "medium" || size == "small"){
      return(
        <div className="NavTableResultsSmall">
          <Selector arraySelect={categories} callback={setActive} returnValue={false}/>
        </div>
      )
    }
  }
  return (
    <>
      <div className="NavTableResults">
        {categories.map((item, index) => {
          return (
            <div key={index} className={`NavTableResults__item ${active === index ? "activeNav":null}`} onClick={()=>{setActive(index)}}>
              <Text size={"b-large"} color={active === index ? "red":"black"}>{item}</Text>
            </div>
          );
        })}
      </div>
      <div className={'legend'}>
        <div>
          <Text>Driver's standings extra scores</Text>
          <div> /</div>
        </div>
        
        <div>
          <Text>Qualy Pole position:</Text><Text><span className='blue'>2 pts.</span></Text>
          <div> /</div>
        </div>
       
        <div>
          <Text>Fastest lap:</Text><Text><span className='red'>+1 pts.</span></Text>
        </div>
      </div>
    </>
  );
};

NavTableResults.defaultProps = {
  responsive: true,
  categories: ["FEAUTURE RACE", "SPRINT RACE", "CALIFICATION", "PRACTICA"],
  callback: (index) => {
    console.log(index);
  }
};

import { convertRaceDate } from "../../../utils/dateConvert"
import { Text } from "../../atoms/Text/Text"
import "./horariosTable.css"

export const HorariosTable = ({circuit}) => {
  if(!circuit["practice_horario_1"]  && !circuit["qualy_horario_1"]  && !circuit["race_horario_1"]) return null

  const timeList = [
    { session: 'PRACTICE 1', time: circuit["practice_horario_1"] ? circuit["practice_horario_1"] : null},
    { session: 'PRACTICE 2', time: circuit["practice_horario_2"] ? circuit["practice_horario_2"] : null},
    { session: 'QUALIFICATION 1', time: circuit["qualy_horario_1"] ? circuit["qualy_horario_1"] : null},
    { session: 'QUALIFICATION 2', time: circuit["qualy_horario_2"] ? circuit["qualy_horario_2"] : null},
    { session: 'RACE 1', time: circuit["race_horario_1"] ? circuit["race_horario_1"] : null},
    { session: 'RACE 2', time: circuit["race_horario_2"] ? circuit["race_horario_2"] : null},
    { session: 'RACE 3', time: circuit["show_race_horario_3"] ? circuit["race_horario_3"] : null},

  ]
  const sortedTimeList = timeList.sort((a,b) => {
    return new Date(a.time).getTime() - 
        new Date(b.time).getTime()
  });
  //console.log(sortedTimeList);

  return (
    <div className="horariosTable">
      <div className="horariosTable__item headrow">
        SCHEDULE
      </div>

      {sortedTimeList.map((item, index) => {
        if (item.time && convertRaceDate(item.time)) { 
          return (
            <div className="horariosTable__item" key={index}>
              <div className="horarioCell">{convertRaceDate(item.time)}</div>
              <Text color={"white"} >{item.session}</Text>
            </div>
          );
        }
      })}
    </div>
  )
}

import React from 'react'
import './driverTemplate.css'
import newsImage from "../../../assets/image/NewsImage.jpg";
import { Text } from '../../atoms/Text/Text'
import { Gallery } from '../../molecules/gallery/Gallery'
import { ListNews } from '../../molecules/lists/listNews/ListNews';
import { DriverInfo } from '../../organisms/driverInfo/DriverInfo';

export const DriverTemplate = ({news,driver,round,teams}) => {
  let teamDriver = teams.find((team)=> team.teamId == driver.teamId)
  if(teamDriver === undefined){
    teamDriver = {
      teamName: "No team",
    }
  }
  return (
    <div>
      <DriverInfo
        team={teamDriver.teamName}
        name={driver.driverName}
        image={driver.driverImage?.url}
        country={driver.country}
        socialIcons={driver.socialIcons}
        number={driver.driverNum}
        char1={{name:"DOB", value:driver.birthDate}}
        char2={{name:"BIO", value:driver.description}}
        stand1={{name:"standing", value: round.totalStanding.indexOf((driver.driverId)?.toString()) === -1 ? "-" : round.totalStanding.indexOf((driver.driverId)?.toString())+ 1 }}
        stand2={{name:"points", value: round.totalPoints[driver.driverId]? round.totalPoints[driver.driverId] : "-"}}
        status={driver.status}
      />
      {/* <section className='driverTemplatesS1'>
        <Text size={"p"} seo={"p"}>
        <div dangerouslySetInnerHTML={{ __html: driver.description}} className='uk-width-expand@m'></div>
        </Text>
      </section> */}
      <section className='driverTemplatesS2'>
        <Gallery/>
      </section>
      <ListNews news={news} categories={[driver.driverName]}/>
    </div>
  )
}

DriverTemplate.defaultProps = {
  driver: [1,2,3,4],
  news: [  
    {
    image: newsImage,
    title:"Believe In Your Print Skills But Never Stop Improving",
    subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
    category:["Categoria"],
    date:"Dec 24, 2016"
    },
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        category:["Categoria"],
        date:"Dec 24, 2016",
        size:"default"
    },
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        category:["Categoria"],
        date:"Dec 24, 2016",
        size:"default"
    },
    {
        image: newsImage,
        title:"Believe In Your Print Skills But Never Stop Improving",
        subtitle:"Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless to say, Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention.",
        category:["Categoria"],
        date:"Dec 24, 2016",
        size:"default"
    }]
}
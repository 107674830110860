import React from 'react'

export const OffCanvas = ({children,id}) => {
  return (
    <div id={id} uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar containerOffCanvas">
            {children}
        </div>
    </div>
  )
}

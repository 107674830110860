import React from 'react'
import './partnerIcons.css'
import { PropTypes } from 'prop-types'
import { Text } from '../../atoms/Text/Text'

export const PartnerIcons = ({title,arrayIcons}) => {

  return (
    <div className='parnerIconsBlock'>
        <Text seo={"h2"} size='h6' color='white'>{title}</Text>
        <div className="listCardCircuits uk-visible-toggle uk-light" tabIndex="-1" uk-slider="center: true; autoplay: true; autoplay-interval: 5000;">
          <ul className="uk-slider-items uk-child-width-1-3 uk-child-width-1-6@m uk-grid">
            {arrayIcons.map((icon,index) => {
                return (
                    <li key={index} ><div dangerouslySetInnerHTML={{__html:icon.rendered}} ></div></li>
                )
              })
            }
          </ul>
        </div>
    </div>
  )
}

PartnerIcons.defaultProps = {
  title: 'title',
  arrayIcons: [
    {
      image: 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
      onclick: () => {}
    },
    {
      image: 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
      onclick: () => {}
    }
  ]
}

PartnerIcons.propTypes = {
  title: PropTypes.string,
  arrayIcons: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    onclick: PropTypes.func
  }))
}
import React from 'react'
import PropTypes from 'prop-types';
import "./tags.css"
import { Text } from '../Text/Text';
import { RiCloseCircleFill } from "react-icons/ri";


export const Tags = ({tagName,deleteTag}) => {
    const style = { color: "white", cursor: "pointer" }
  return (
    <div className='tags'><Text size='label' color='white' >{tagName}</Text> <RiCloseCircleFill style={style} onClick={deleteTag}/></div>
  )
}

Tags.defaultProps = {
    tagName: 'tagName',
    deleteTag: () => {}
}